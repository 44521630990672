import React, { useState } from 'react';

const Modal = ({ show, onClose, onSubmit }) => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [licenseCount, setLicenseCount] = useState('1');
  const [freeText, setFreeText] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      "email": email,
      "fullName": fullName,
      "phoneNumber": phoneNumber,
      "licenseCount": licenseCount,
      "freeText": freeText
    };
    onSubmit(data);
    onClose();
  };

  if (!show) return null;

  return (
    <div style={{fontSize:11}} className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      <form style={{backgroundColor:'#1f1f1f', fontFamily:"open sans hebrew"}}
            className="p-8 shadow-md rounded-md rounded-xl text-white w-[25%]"
            onSubmit={handleSubmit}
      >
        <h2 className="text-xl text-right font-bold mb-6">לצפייה בנתונים יש להירשם כמנוי</h2>
        <p className="mb-4 text-right">פנה אלינו לקבלת הצעה</p>

        <div className="mb-4">
          <label htmlFor="fullName" className="block text-sm font-bold mb-2">שם מלא</label>
          <input
              required
            type="text"
            id="fullName"
            className="w-full p-2  rounded-md text_box_color"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-bold mb-2">מייל</label>
          <input
              required
            type="email"
            id="email"
            className="w-full p-2  rounded-md text_box_color"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="phoneNumber" className="block text-sm font-bold mb-2">סלולרי</label>
          <input
              required
            type="text"
            id="phoneNumber"
            className="w-full p-2  rounded-md text_box_color"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="licenseCount" className="block text-sm font-bold mb-2">מספר רישיונות</label>
          <select
              required
            id="licenseCount"
            className="w-full p-2  rounded-md text_box_color"
            value={licenseCount}
            onChange={(e) => setLicenseCount(e.target.value)}
          >
            {[1,2,3,4,5,6,7,8,9,10].map(num => <option key={num} value={num}>{num}</option>)}
            <option value="10+">10+</option>
          </select>
        </div>

        <div className="mb-4">
          <label htmlFor="freeText" className="block text-sm font-bold mb-2">כתוב לנו</label>
          <textarea
              required
            id="freeText"
            rows="4"
            className="w-full p-2  rounded-md text_box_color"
            value={freeText}
            onChange={(e) => setFreeText(e.target.value)}
          />
        </div>

        <button
          type="submit"
          className="w-full orange_color text-white p-2 rounded-md hover:bg-orange-600"
        >
          שלח
        </button>

        <button
          type="button"
          onClick={onClose}
          className="mt-4 w-full bg-gray-500 text-white p-2 rounded-md hover:bg-gray-600"
        >
          סגור
        </button>
      </form>
    </div>
  );
};

export default Modal;
