import React from "react";
import ReactApexChart from "react-apexcharts";
import {formatNumber} from "../../utils"
import {mapEnglishToHebrew} from "../utils";

const ApexChart = React.memo((
    {
        data,
        numBins,
        legands,
        rightChartCatagories,
        selected_data_column,
        num_multi_search,

    }) => {
    const titles = {
        0:"גמל",
        1:"פנסיה",
        2:"ביטוח",
        3:"קרנות נאמנות",
        4:"קרנות מחקות וסל",
        5:"גמל",
        6:"פנסיה",
        7:"ביטוח",
        8:"קרנות נאמנות",
        9:"קרנות מחקות וסל"
    }
    const options = {
        chart: {
            type: "bar",
            width: '700px',
            // height: 950,
            // width: 700,
            stacked: true,
            toolbar: {
                show: false,
            },

        },
        dataLabels: {
            enabled: true,
            formatter: function (value) {
                if (value >= 1e9) {
                    return (value / 1e9).toFixed(2) + ' B';
                } else if (value >= 1e6) {
                    return (value / 1e6).toFixed(2) + ' M';
                } else if (value >= 1e3) {
                    return (value / 1e3).toFixed(2) + ' K';
                } else if (value < 1) {
                    value = value.toFixed(2)
                } else {
                    value = value.toFixed(2)
                }
                if (selected_data_column === "sum_par_percentage") {
                    return `${value} %`;
                }
                return `${value}`;
            },
        },

        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: "20px",
                // columnWidth: '100%',
                dataLabels: {
                    total: {
                        enabled: false,
                        offsetX: 0,
                        style: {
                            fontSize: "12px",
                            fontWeight: 900,
                        },
                    },
                },
            },
        },
        stroke: {
            width: 3,
            colors: ["transparent"],
        },
        colors: ["#E76D3B", "#2dbda8", "#AA1985", "#84B547", "#7F7F7F",],
        xaxis: {
            categories: rightChartCatagories,
            labels: {
                formatter: function (val) {
                    return formatNumber(val);
                },
                style: {
                    colors: "#fff",
                },
                rotate: 50
            },
        },
        yaxis: {
            show: false,
            reversed: true,
        },
        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              // Customize the tooltip content with a card-like structure
                console.log(seriesIndex)
              return (
                `<div class="custom-tooltip">
                  <div style="background-color: ${w.config.colors[seriesIndex]}" class="tooltip-header text-center ">
                    <div class="tooltip-title">${titles[seriesIndex%5]}</div>
                  </div>
                  <div class="tooltip-content text-right">
                    <div class="data-point text-right">${w.config.xaxis.categories[dataPointIndex]}</div>
                    <div class="value text-right">${series[seriesIndex][dataPointIndex].toLocaleString('en-US', { maximumFractionDigits: 0 })}</div>
                  </div>
                </div>`
              );
            },
          },
        fill: {
            opacity: 1,
        },
        legend: {
            fontWeight: 'bold',
            position: "top",
            horizontalAlign: "right",
            offsetX: 40,
            inverseOrder: true,
            customLegendItems: legands,
            markers: {
                fillColors: ["#E76D3B", "#2dbda8", "#AA1985", "#84B547", "#7F7F7F",]
            },
            formatter: function (seriesName, {series, seriesIndex, w}) {
                if (w.globals.series[seriesIndex]) {
                    // Calculate the sum of values for the current legend
                    let value = w.globals.series[seriesIndex].reduce((acc, curr) => acc + curr, 0);
                    value = Math.abs(value)
                    if (value >= 1e9) {
                        value = (value / 1e9).toFixed(2) + ' B';
                    } else if (value >= 1e6) {
                        value = (value / 1e6).toFixed(2) + ' M';
                    } else if (value >= 1e3) {
                        value = (value / 1e3).toFixed(2) + ' K';
                    } else if (value < 1) {
                        value = value.toFixed(2)
                    } else {
                        value = value.toFixed(2)
                    }
                    if (selected_data_column === "sum_par_percentage") {
                        value = value.toString() + " %"
                    }
                    value = "<br/> "+ value
                    if (num_multi_search > 1) {
                        value = ""
                    }

                    if (seriesName.includes("<br/>")) {
                        return `${mapEnglishToHebrew(seriesName.split("<br/>")[1])} ${value} `;
                    } else if(seriesName.includes("<br />")) {
                        // console.log(seriesName)
                        return `${seriesName.split("<br /> ")[0]} <br/> ${mapEnglishToHebrew(seriesName.split("<br /> ")[1])} ${value} `;
                    }else{
                        return `${mapEnglishToHebrew(seriesName.split("<br /> ")[0])} ${value} `;
                    }
                    }
                if (seriesName.includes("<br/>")) {
                    return mapEnglishToHebrew(seriesName.split("<br/>")[1]);
                } else {

                    return mapEnglishToHebrew(seriesName);
                }
                // return seriesName;
            },
        },
        grid: {

            padding: {

            },
            borderColor: "#2f2f2f",
            strokeDashArray: 0,
            position: "back",
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: false,
                },
            },
            row: {
                colors: undefined,
                opacity: 0.5,
            },
        },
    }

    return (

            <div
                id="chart"
                className=" w-full  text-black third "
            >
                {/*{display ?*/}
                    <ReactApexChart
                        options={options}
                        series={data}
                        type="bar"
                        height={(numBins)}
                    />
                {/*: <div></div>}*/}
            </div>

    )
})

export default ApexChart