import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2'
import axios from "axios";

const SignupPage = ({handleLogin}) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [companyNameUser, setCompanyNameUser] = useState('');
    const [passwordUser, setPasswordUser] = useState('');
    const [passwordUserConfirm, setPasswordUserConfirm] = useState('');
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [jobTitleError, setJobTitleError] = useState(false);
    const [companyNameError, setCompanyNameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [passwordConfirmError, setPasswordConfirmError] = useState(false);
    const navigate = useNavigate();
    const [policyAgreement, setPolicyAgreement] = useState(false);

    const handleLoginClick = () => {
        navigate('/login');
    };
    const HandleSignupUser = async (e) => {
        console.log('data')
        e.preventDefault();
        setFirstNameError(false);
        setLastNameError(false);
        setEmailError(false);
        setPhoneNumberError(false);
        setJobTitleError(false);
        setCompanyNameError(false);
        setPasswordError(false);
        setPasswordConfirmError(false);

        // Validation checks
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (!firstName) setFirstNameError(true);
        if (!lastName) setLastNameError(true);
        if (!email || !emailRegex.test(email)) setEmailError(true);
        if (!phoneNumber) setPhoneNumberError(true);
        // if (!jobTitle) setJobTitleError(true);
        // if (!companyNameUser) setCompanyNameError(true);
        if (!passwordUser) setPasswordError(true);
        if (passwordUser !== passwordUserConfirm) {
            setPasswordError(true);
            setPasswordConfirmError(true);
        }
        if (!policyAgreement || !firstName || !lastName || !email || !phoneNumber ||  !passwordUser || !passwordUserConfirm) {
            return;
        }
        const userData = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone_number: phoneNumber,
            job_title: jobTitle,
            company_name: companyNameUser,
            password: passwordUser
        };

        try {

            const response = await axios.post('/accounts/register/user/', userData)

            if (response.status === 200 || response.status === 201) {
                const data = response.data;

                Cookies.set('email', data.email);
                Cookies.set('refreshToken', data.refresh);
                Cookies.set('accessToken', data.access);

                // Route to home after successful registration
                handleLogin()
                navigate('/');
            } else {
                console.error("Unexpected server response:", response.data);
                // Handle any other server responses here, you can add more conditions to check for other status codes
            }
        } catch (error) {
            console.log("error")
            console.log(error)
            if (error.response && error.response.status === 400) {

                console.error("User already exists.");
                Swal.fire({
                    title: 'Email already in use',
                    text: error.response.data.email[0],
                    icon: 'error',
                    // confirmButtonText: 'Close'
                    confirmButtonText: 'Close'
                })
            } else {
                Swal.fire({
                    title: error.code,
                    text: error.message,
                    icon: 'error',
                    // confirmButtonText: 'Close'
                    confirmButtonText: 'Close'
                })
            }


        }

    };

    return (
        <div  style={{fontFamily:"open sans hebrew"}} className="flex items-center h-[92vh] w-[100%] justify-center  bg-transparent">
            <div dir="rtl" className="flex justify-center w-full mx-5 my-2  ">
                <div  className="w-1/2 my-auto  text-white">
                    <div style={{maxWidth:600}} className="mx-auto " >
                    <h2 className="text-4xl font-bold mb-6">Welcome to BrokersConnect</h2>
                    <p className="text-white mb-4">
                        Connect with top industry professionals. Elevate your network by joining BrokersConnect today!
                        Make meaningful connections and grow your career. Don't miss out!. Join BrokersConnect today!
                    </p>
                    <div className="mb-4">
                        <p className="mb-4">
                             כבר יש לך חשבון?  <a onClick={handleLoginClick} className="orange_font">התחבר</a>
                        </p>
                    </div>
                        </div>
                </div>
                <div className="w-1/2  text-white">
                    <div>
                    <form style={{backgroundColor: '#1f1f1f', width:600}}
                          className="bg-black p-8 shadow-md rounded-md mx-auto"
                          onSubmit={HandleSignupUser}>
                        <h2 className="text-2xl text-center font-bold mb-6">
                            הרשמה
                        </h2>
                        {/* User form fields */}
                        <div className="flex">
                            <div className="w-1/2 m-2">
                                <div className="mb-4">
                                    <label htmlFor="first-name"
                                           className="block text-white text-sm font-bold mb-2">
                                        שם פרטי
                                    </label>
                                    <input
                                        type="text"
                                        id="first-name"
                                        className={`w-full p-2 ${firstNameError ? 'border orange_font_border' : 'border-gray-600'} rounded-md text_box_color`}
                                        value={firstName}
                                        onChange={(e) => {
                                            setFirstName(e.target.value)
                                            setFirstNameError(false)
                                        }}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="email"
                                           className="block text-white text-sm font-bold mb-2">
                                        מייל
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        className={`w-full p-2 ${emailError ? 'border orange_font_border' : 'border-gray-600'} rounded-md text_box_color`}
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value)
                                            setEmailError(false)
                                        }}
                                    />
                                </div>

                            </div>
                            <div className="w-1/2 m-2">
                                <div className="mb-4">
                                    <label htmlFor="last-name"
                                           className="block text-white text-sm font-bold mb-2">
                                        שם משפחה
                                    </label>
                                    <input
                                        type="text"
                                        id="last-name"
                                        className={`w-full p-2 ${lastNameError ? 'border orange_font_border' : 'border-gray-600'} rounded-md text_box_color`}
                                        value={lastName}
                                        onChange={(e) => {
                                            setLastName(e.target.value)
                                            setLastNameError(false)
                                        }}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="phone-number"
                                           className="block text-white text-sm font-bold mb-2">
                                        סלולרי
                                    </label>
                                    <input
                                        type="tel"
                                        id="phone-number"
                                        className={`w-full p-2 ${phoneNumberError ? 'border orange_font_border' : 'border-gray-600'} rounded-md text_box_color`}
                                        value={phoneNumber}
                                        onChange={(e) => {
                                            setPhoneNumber(e.target.value)
                                            setPhoneNumberError(false)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mx-2">
                            <div className="mb-4">
                                <label htmlFor="job-title"
                                       className="block text-white text-sm font-bold mb-2">
                                    תפקיד
                                </label>
                                <input
                                    type="text"
                                    id="job-title"
                                    className={`w-full p-2 ${jobTitleError ? 'border orange_font_border' : 'border-gray-600'} rounded-md text_box_color`}
                                    value={jobTitle}
                                    onChange={(e) => {
                                        setJobTitle(e.target.value)
                                        setJobTitleError(false)
                                    }}
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="company-name-user"
                                       className="block text-white text-sm font-bold mb-2">
                                    חברה
                                </label>
                                <input
                                    type="text"
                                    id="company-name-user"
                                    className={`w-full p-2 ${companyNameError ? 'border orange_font_border' : 'border-gray-600'} rounded-md text_box_color`}
                                    value={companyNameUser}
                                    onChange={(e) => {
                                        setCompanyNameUser(e.target.value)
                                        setCompanyNameError(false)
                                    }}
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="password-user"
                                       className="block text-white text-sm font-bold mb-2">
                                    סיסמה
                                </label>
                                <input
                                    type="password"
                                    id="password-user"
                                    className={`w-full p-2 ${passwordError ? 'border orange_font_border' : 'border-gray-600'} rounded-md text_box_color`}
                                    value={passwordUser}
                                    onChange={(e) => {
                                        setPasswordUser(e.target.value)
                                        setPasswordError(false)
                                    }}
                                />
                            </div>

                            {/* Confirm Password */}
                            <div className="mb-4">
                                <label htmlFor="password-user-confirm"
                                       className="block text-white text-sm font-bold mb-2">
                                    הקלד סיסמה לאימות
                                </label>
                                <input
                                    type="password"
                                    id="password-user-confirm"
                                    className={`w-full p-2 ${passwordConfirmError ? 'border orange_font_border' : 'border-gray-600'} rounded-md text_box_color`}
                                    value={passwordUserConfirm}
                                    onChange={(e) => {
                                        setPasswordUserConfirm(e.target.value)
                                        if (passwordUser !== e.target.value) {
                                            setPasswordConfirmError(true)
                                        } else {
                                            setPasswordConfirmError(false)
                                            setPasswordError(false)
                                        }
                                    }
                                    }
                                />
                            </div>
                            <div className="mb-4">
                                <input
                                    type="checkbox"
                                    id="policy-agreement"
                                    onChange={() => setPolicyAgreement(!policyAgreement)}
                                    className="mr-2"
                                    required
                                />
                                &nbsp;
                                <label htmlFor="policy-agreement" className="text-white text-sm">
                                      אני מאשר/ת את  <a href=""  className="underline orange_font">תנאי השימוש</a>  וקבלת פניות שיווקיות
                                </label>
                            </div>

                            <button type="submit"
                                    className="px-5 orange_color text-white p-2 rounded-md text_box_color hover:bg-orange-700">
                                הרשם
                            </button>
                        </div>

                    </form>
                        </div>
                </div>
            </div>
        </div>
    );
};

export default SignupPage;
