import React, {useCallback, useEffect, useState, useRef} from "react";
import PropTypes from "prop-types";
import "./multiRangeSlider.css";
import {formatNumber} from "../../../utils"

const MultiRangeSlider = ({lowerLimit, upperLimit, currentLower, currentUpper, onChange, actual, setOnHover}) => {
    const [minVal, setMinVal] = useState(currentLower);
    const [maxVal, setMaxVal] = useState(currentUpper);
    const minValRef = useRef(currentLower);
    const maxValRef = useRef(currentUpper);
    const range = useRef(null);

    const getPercent = useCallback(
        (value) => Math.round(((value - lowerLimit) / (upperLimit - lowerLimit)) * 100),
        [lowerLimit, upperLimit]
    );

    const onMouseEnter = e => {
        setOnHover(true)
    };

    const onMouseLeave = e => {
        setOnHover(false)
    };

    useEffect(() => {
        const minPercent = getPercent(minVal);
        const maxPercent = getPercent(maxValRef.current);

        if (range.current) {
            range.current.style.left = `${minPercent}%`;
            range.current.style.width = `${maxPercent - minPercent}%`;
        }
    }, [minVal, getPercent]);

    useEffect(() => {
        const minPercent = getPercent(minValRef.current);
        const maxPercent = getPercent(maxVal);

        if (range.current) {
            range.current.style.width = `${maxPercent - minPercent}%`;
        }
    }, [maxVal, getPercent]);

    useEffect(() => {
        onChange({min: minVal, max: maxVal});
    }, [minVal, maxVal, onChange]);

    return (
        <div className="container_">
            <input
                type="range"
                min={lowerLimit}
                max={upperLimit}
                value={minVal}
                onChange={(event) => {
                    const value = Math.min(Number(event.target.value), maxVal - 1);
                    setMinVal(value);
                    minValRef.current = value;
                }}
                className="thumb thumb--left"
                style={{zIndex: minVal > upperLimit - 100 && "5"}}
            />
            <input
                type="range"
                min={lowerLimit}
                max={upperLimit}
                value={maxVal}
                onChange={(event) => {
                    const value = Math.max(Number(event.target.value), minVal + 1);
                    setMaxVal(value);
                    maxValRef.current = value;
                }}
                className="thumb thumb--right"
            />

            <div className="slider">
                <div className="slider__track">

                    <div
                        onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
                        className="red-dot"
                        style={{left: `${getPercent(actual)}%`}} // Set the position of the red dot
                    />
                </div>

                <div ref={range} className="slider__range"/>

                <div className="slider__left-value">{formatNumber(minVal)}</div>
                <div className="slider__right-value">{formatNumber(maxVal)}</div>

                <div className="slider__left-value_min">{formatNumber(lowerLimit)}</div>
                <div className="slider__right-value_max">{formatNumber(upperLimit)}</div>
            </div>
            {/*<div className="">*/}
            {/*    {lowerLimit} - {upperLimit}*/}
            {/*</div>*/}
        </div>
    );
};


MultiRangeSlider.propTypes = {
    lowerLimit: PropTypes.number.isRequired,
    upperLimit: PropTypes.number.isRequired,
    currentLower: PropTypes.number.isRequired,
    currentUpper: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
};

export default MultiRangeSlider;
