import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../index.css';

const TopBar = ({ isLoggedIn, onSearchClick, onLoginClick, onLogoutClick }) => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    onSearchClick();
    navigate('/');
  };

  const handleLoginClick = () => {
    onLoginClick();
    isLoggedIn ? navigate('/') : navigate('/login');
  };

  return (
    <>
      <div style={{fontSize:11}} className='font-bold top-bar h-[3vh] py-3 px-4 flex justify-between items-center'>
        <div className='logo'>
          <p className='text-white text-md'>BrokersConnect</p>
        </div>
        <nav dir="rtl" className='nav'>
          <ul className='flex space-x-4'>
            <li>
              <button
                onClick={handleHomeClick}
                className='text-white hover:underline focus:outline-none'
              >
                עמוד בית
              </button>
            </li>
            <li>
              <button
                onClick={handleLoginClick}
                className='text-white hover:underline focus:outline-none'
              >
                {isLoggedIn ? '' : 'התחבר'}
              </button>
            </li>
            {isLoggedIn && (
              <li>
                <button
                  onClick={onLogoutClick}
                  className='text-white hover:underline focus:outline-none'
                >
                  התנתק
                </button>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </>
  );
}

export default TopBar;
