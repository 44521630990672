export function formatNumber(number) {
    const isNegative = number < 0;
    number = Math.abs(number); // Make number positive for formatting

    const abbreviations = {
        K: 3,
        M: 6,
        B: 9,
        T: 12
    };

    const sortedKeys = Object.keys(abbreviations).sort((a, b) => abbreviations[b] - abbreviations[a]);

    for (const key of sortedKeys) {
        if (number >= Math.pow(10, abbreviations[key])) {
            const formatted = (number / Math.pow(10, abbreviations[key])).toFixed(2);
            return `${isNegative ? '-' : ''}${formatted} ${key}`;
        }
    }

    return isNegative ? `-${number.toString()}` : number.toString();
}