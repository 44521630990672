import React from 'react';
import './dror.css';

const DrorLoader = () => {
  return (
    <div className="loader">
      <div className="dot dot-1"></div>
      <div className="dot dot-2"></div>
      <div className="dot dot-3"></div>
      <div className="dot dot-4"></div>
      <div className="dot dot-5"></div>
    </div>
  );
};

export default DrorLoader;