export function convertDateFormat(inputDate) {
    if (!(inputDate && inputDate.length === 10)) {
        return "";
    } else {
        const parts = inputDate.split('-');
        const year = parseInt(parts[0]);
        const month = parseInt(parts[1]);
        const currentDate = new Date(year, month - 1);
        const options = {year: '2-digit', month: 'short'};
        return currentDate.toLocaleDateString('en-US', options);
    }

}

export function mapEnglishToHebrew(englishText) {
    const dictionary = {
        "provident": "גמל",
        "pension": "פנסיה",
        "insurance": "ביטוח",
        "mutual funds": "קרנות נאמנות",
        "mutual fund etf": "קרנות מחקות וסל",
        "jan" :"ינו",
        "feb" :"פבר",
        "mar" :"מרץ",
        "apr" :"אפר",
        "may" :"מאי",
        "jun" :"יונ",
        "jul" :"יול",
        "aug" :"אוג",
        "sep" :"ספט",
        "oct" :"אוק",
        "nov" :"נוב",
        "dec" :"דצמ",
    };

    // Convert to lowercase for case-insensitive mapping


    // Check if the English text exists in the dictionary
    if (englishText && dictionary.hasOwnProperty(englishText.toString().toLowerCase())) {
        return dictionary[englishText.toString().toLowerCase()];
    } else {
        return englishText;
    }
}

export function getQuarterYear(inputDate) {
    if (!(inputDate && inputDate.length === 10)) {
        return inputDate;
    } else {
        const [year, month, day] = inputDate.split('-').map(Number);
        const quarter = Math.floor((month - 1) / 3) + 1;
        return `${year} Q${quarter}`;
    }
}