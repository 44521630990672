import React, {useEffect, useRef} from "react";
import ApexChart from "./horizontalChart";
import ApexBar from "./stackedBarChart/apexBar";
import ApexBar2 from "./stackedBarChart2/apexBar2";
import {FaCaretDown, FaCaretUp} from "react-icons/fa";
import {IoStarSharp} from "react-icons/io5";
import DrorLoader from "./drorLoader/DrorLoader";
import {convertDateFormat, getQuarterYear, mapEnglishToHebrew} from "./utils";
// import B from "./chart"
const Charts = ({
                    // Right Chart Data
                    series,
                    // Right Table Data
                    labels_data,
                    // Right Chart Total Bins
                    num,
                    // Right Table click event
                    handleRowClick,
                    // Right table hebrew name
                    allLabelsNames,
                    allQuarterNames,
                    // Left Top chart data
                    bar_monthly_data,
                    bar_monthly_categories,
                    //Right top chart data
                    bar_quarterly_data,
                    bar_quarterly_categories,
                    grandSum,
                    selectedRowId,

                    handleCustomDropdownTop,
                    // dates for checkboxes
                    dates,
                    handleCheckMonth,
                    handleCheckQuarter,
                    checkedValuesMonths,
                    checkedValuesQuarter,
                    display,
                    legands,
                    num_quarters,
                    loading,
                    loadingleft,
                    // displayleft,
                    handleCustomDropdown,
                    customDropdown,
                    num_multi_search,
                    selected_data_column,
                    rightChartCatagories,
                    secType,
                    handleSelectionChange_,
                    column,
                    keywords,
                    column2,
                    handleSelectionChange2_,
                    activate_star_function,
                    starActive,
                    isCustomFilter,
                    upper_chart_msg,
                    searchedSecName,
                    sec_id_search
                }) => {
    const [one, setOne] = React.useState(true);

    const handleStarClick = () => {
        activate_star_function(!starActive)
    };

    const isGroupChart = () => {
        // console.log(checkedValuesQuarter.filter(item => item === true).length, num_multi_search, checkedValuesMonths.filter(item => item === true).length)
        if (checkedValuesQuarter.filter(item => item === true).length === 0){
            if (checkedValuesMonths.filter(item => item === true).length > 1 || num_multi_search > 1){
                return true
            }
        }else if(checkedValuesQuarter.filter(item => item === true).length > 1 || num_multi_search > 1){
            return true
        }
        return false
    }

    const dynamicMarginBottom = () => {
        if (checkedValuesQuarter.filter(item => item === true).length === 0){
            if (checkedValuesMonths.filter(item => item === true).length > 1 || num_multi_search > 1){
                return checkedValuesMonths.filter(item => item === true).length
            }
        }else if(checkedValuesQuarter.filter(item => item === true).length > 1){
            return checkedValuesQuarter.filter(item => item === true).length
        }
        return 1
    }


    useEffect(() => {
    }, []);
    const getYearWithQuarter = (dateString) => {
        if (!dateString) {
            return ""
        }
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const quarter = Math.ceil(month / 3);
        return `${year} Q${quarter}`;
    }

    const rapnum = (value) => {
        // console.log(value)
        value = parseFloat(value)
        if (value >= 1e9) {
            return (value / 1e9).toFixed(2) + ' B';
        } else if (value >= 1e6) {
            return (value / 1e6).toFixed(2) + ' M';
        } else if (value >= 1e3) {
            return (value / 1e3).toFixed(2) + ' K';
        } else if (value < 1) {
            value = value.toFixed(2)
        } else {
            value = value.toFixed(2)
        }
        return value
    }


    const handleButtonClick = (val, one_two) => {
        if (one_two === '1') {
            setOne(true)
        } else {
            setOne(false)
        }
        console.log(one_two)
        handleSelectionChange_(val, one_two)
    };

    const handleButton2Click = (val) => {
        handleSelectionChange2_(val)
    };

    const checkDisable = (num_multi_search, secType) => {
        if (num_multi_search > 1 || secType[0] === '0' || secType[0] === '15') {
            return true
        }
        return false
    }

    const checkDisableColumn2 = (col) => {
        if (num_multi_search > 1 || secType[0] === '0' || secType[0] === '15' || num_quarters > 1) {
            return "none"
        }
        if (isCustomFilter) {
            if (col === "holders" || col === 'non_holders')
                return "none"
        }
        return 'true'
    }

    const checkVisibleColumn2 = (col) => {
        if (num_multi_search > 1 || secType[0] === '0' || secType[0] === '15' || num_quarters > 1) {
            return "none"
        }
        if (isCustomFilter) {
            if (col === "holders" || col === 'non_holders')
                return "none"
        }
        return "block"
    }

    const checkDisableDropdown = (secType) => {
        if (num_multi_search > 1 || secType[0] === '0' || secType[0] === '15' || num_quarters > 1) {
            return true
        }
        return false
    }
    const getTableStyle = (num_quarters, val) => {
        return {
            marginTop: 10,
            border: "1px solid #1f1f1f"
        };
    };
    const getRowStyle = (val, sec_name) => {
        if (num_quarters > 1) {
            sec_name = ""
        }
        return {
            height: 20,
            backgroundColor: selectedRowId === val.toString() + sec_name ? 'grey' : allLabelsNames[val] === searchedSecName ? 'gray' : "",
        };


    };
    return (
        <div className="text-white charts">
            <div className="box-border flex  justify-between items-start m-2 p-2 ">
                <div className="box-border w-[50%] pr-4 text-black">

                    <div style={{backgroundColor: '#1f1f1f'}} className=" w-[100%] h-[360px]  rounded-[3px]  p-4 ">
                        <p style={{
                            marginTop: 140,
                            display: bar_monthly_categories.length > 0 ? "none" : !upper_chart_msg ? "none" : !loadingleft ? "block" : "none"
                        }} className="text-white text-center w-[45%] font-bold absolute">אין היסטוריה עבור החיפוש
                            המבוקש</p>

                        <ApexBar data={bar_monthly_data} categories={bar_monthly_categories}/>
                        <div>
                            {loadingleft &&
                                // <img height={100} width={100} style={{marginLeft: 380, marginTop: -250}}
                                //                  src="loading1.gif" alt="Loading"/>
                                <div style={{marginTop: -175}}>
                                    <DrorLoader/>
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{backgroundColor: '#1f1f1f'}}
                         className=" w-[100%] h-[360px] rounded-[3px] p-4 mb-2 mt-4">
                        <p style={{
                            marginTop: 135,
                            display: bar_quarterly_categories.length > 0 ? "none" : !upper_chart_msg ? "none" : !loadingleft ? "block" : "none"
                        }} className="text-white text-center w-[45%] font-bold absolute">אין היסטוריה עבור החיפוש
                            המבוקש</p>

                        <ApexBar2 data={bar_quarterly_data} categories={bar_quarterly_categories}/> :
                        <div>
                            {loadingleft &&
                                // <img height={100} width={100} style={{marginLeft: 380, marginTop: -250}}
                                //                  src="loading1.gif" alt="Loading"/>
                                <div style={{marginTop: -195}}>
                                    <DrorLoader/>
                                </div>
                            }
                        </div>
                    </div>


                </div>
                <div style={{backgroundColor: '#1f1f1f'}}
                     className="right_chart box-border w-[50%] h-[736px] overflow-auto rounded-[3px] flex justify-between items-start text-white bg-black  p-4">
                    <div className="w-[75%] ">
                        {display ?
                            <div className="relative">
                                {
                                    secType[0] === '0' || secType[0] === '15' ?

                                        <div className="flex justify-end items-center py-2 options">
                                            <p
                                                style={
                                                    {
                                                        fontWeight: column === "control_corp" ? "bold" : "normal",
                                                        color: column === "control_corp" ? "#ffffff" : "#ffffff80"
                                                    }
                                                }
                                                onClick={() => handleButtonClick("control_corp", '1')}
                                                className="cursor-pointer ml-4 pb-2 text-[#ffffff80] hover:text-[#ffffff]">
                                                אחזקה מצרפית
                                            </p>
                                            {
                                                keywords[0].slice(0, 3) === "ענף" ?
                                                    <>
                                                         <p onClick={() => handleButtonClick("sec_id", '2')}
                                                           style={
                                                               {
                                                                   fontWeight: column === "sec_id" && !one ? "bold" : "normal",
                                                                   color: column === "sec_id" && !one ? "#ffffff" : "#ffffff80"
                                                               }
                                                           }
                                                           className="cursor-pointer ml-4 pb-2 text-[#ffffff80] hover:text-[#ffffff] ">

                                                            {/*רשימת ני”ע 2*/}
                                                            אג”ח
                                                        </p>
                                                        <p onClick={() => handleButtonClick("sec_id", '1')}
                                                           style={
                                                               {
                                                                   fontWeight: column === "sec_id" && one ? "bold" : "normal",
                                                                   color: column === "sec_id" && one ? "#ffffff" : "#ffffff80"
                                                               }
                                                           }
                                                           className="cursor-pointer ml-4 pb-2 text-[#ffffff80] hover:text-[#ffffff] ">

                                                            {/*רשימת ני”ע 1*/}
                                                            מניות
                                                        </p>

                                                    </>
                                                    :
                                                    <p onClick={() => handleButtonClick("sec_id", '1')}
                                                       style={
                                                           {
                                                               fontWeight: column === "sec_id" ? "bold" : "normal",
                                                               color: column === "sec_id" ? "#ffffff" : "#ffffff80"
                                                           }
                                                       }
                                                       className="cursor-pointer ml-4 pb-2 text-[#ffffff80] hover:text-[#ffffff] ">

                                                        רשימת ני”ע
                                                    </p>
                                            }

                                        </div>
                                        :
                                        <div className="flex justify-end items-center py-2 options">


                                            <p
                                                style={
                                                    {

                                                        display: checkVisibleColumn2("holders_similar"),
                                                        fontWeight: column2 === "holders_similar" ? "bold" : "normal",
                                                        color: column2 === "holders_similar" ? "#ffffff" : "#ffffff80",
                                                        pointerEvents: checkDisableColumn2("holders_similar")
                                                    }
                                                }
                                                onClick={() => handleButton2Click("holders_similar",)}
                                                className="cursor-pointer ml-4 pb-2 text-[#ffffff80] hover:text-[#ffffff]">
                                                אחזקות בני”ע דומים
                                            </p>
                                            <p
                                                style={
                                                    {
                                                        display: checkVisibleColumn2('sectors'),
                                                        fontWeight: column2 === "sectors" ? "bold" : "normal",
                                                        color: column2 === "sectors" ? "#ffffff" : "#ffffff80",
                                                        pointerEvents: checkDisableColumn2('sectors')
                                                    }
                                                }
                                                onClick={() => handleButton2Click("sectors")}
                                                className="cursor-pointer ml-4 pb-2 text-[#ffffff80] hover:text-[#ffffff]">
                                                רשימת ני”ע דומים
                                                {/*sectors*/}
                                            </p>

                                            <p
                                                style={
                                                    {
                                                        display: checkVisibleColumn2("non_holders"),
                                                        fontWeight: column2 === "non_holders" ? "bold" : "normal",
                                                        color: column2 === "non_holders" ? "#ffffff" : "#ffffff80",
                                                        pointerEvents: checkDisableColumn2("non_holders")
                                                    }
                                                }
                                                onClick={() => handleButton2Click("non_holders",)}
                                                className="cursor-pointer ml-4 pb-2 text-[#ffffff80] hover:text-[#ffffff]">
                                                שאינם מחזיקים
                                            </p>
                                            <p
                                                style={
                                                    {
                                                        display: checkVisibleColumn2("holders"),
                                                        fontWeight: column2 === "holders" ? "bold" : "normal",
                                                        color: column2 === "holders" ? "#ffffff" : "#ffffff80",
                                                        pointerEvents: checkDisableColumn2("non_holders")
                                                    }
                                                }
                                                onClick={() => handleButton2Click("holders",)}
                                                className="cursor-pointer ml-4 pb-2 text-[#ffffff80] hover:text-[#ffffff]">
                                                אחזקה מצרפית
                                            </p>
                                        </div>
                                }
                                <ApexChart
                                    data={series}
                                    numBins={num}
                                    legands={legands}
                                    rightChartCatagories={rightChartCatagories}
                                    selected_data_column={selected_data_column}
                                    num_multi_search={num_multi_search}
                                />
                            </div> :
                            <div>{loading &&
                                <div style={{marginTop: 320, marginLeft: 200}}>
                                    <DrorLoader/>
                                </div>
                            }</div>
                        }

                    </div>

                    <div className="vals w-[25%] ">
                        <div className="header flex justify-between items-center">
                            <div className="flex h-[45px] rounded justify-center items-center bg-[#292E32] w-full px-2">
                                <div className="select-wrapper text-center text-white relative">
                                    <select
                                        dir="rtl"
                                        id="selectt"
                                        className=" bg-transparent border-2 hover:bg-transparent m-1 p-0 font-bold"
                                        style={{
                                            marginTop: 30,
                                            marginLeft: 0,
                                            marginRight: 0,
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                            paddingBottom: 20,
                                            border: 0,
                                            outline: "none",
                                            fontSize: 14
                                        }}
                                        onChange={handleCustomDropdownTop}
                                    >
                                        <option disabled={checkDisableDropdown(secType)} style={{fontSize: 16,}}
                                                className="dropdown-content menu p-2 shadow bg-black w-[100px] text-right">
                                            {/*Market Cap*/}
                                            שווי שוק

                                        </option>
                                        <option disabled={checkDisableDropdown(secType)} style={{fontSize: 16}}
                                                className="text-right dropdown-content menu p-2 shadow bg-black w-[100px]">
                                            {/*0% of capital*/}
                                            אחוז מהון רשום
                                        </option>
                                        <option disabled={checkDisableDropdown(secType)} style={{fontSize: 16}}
                                                className="text-right dropdown-content menu p-2 shadow bg-black w-[100px]">
                                            {/*Par Value*/}
                                            ערך נקוב
                                        </option>

                                    </select>
                                    <p style={{
                                        left: 50,
                                        top: -27,
                                        position: "relative",
                                        fontSize: 16,
                                        fontWeight: "bold"
                                    }} className="text-right  heb font-semibold mb-1">
                                        {num_multi_search === 1 ?
                                            <b>{grandSum}{selected_data_column === "sum_par_percentage" ? "%" : ""}</b> :
                                            "0"}
                                    </p>
                                </div>
                            </div>
                            <div
                                onClick={handleStarClick}
                                className="h-[45px] rounded bg-[#292E32] ml-2  min-w-[38px] flex justify-center items-center"
                            >
                                <IoStarSharp
                                    className={`m-auto text-2xl  ${
                                        starActive ? "text-[#F7B84B]" : "text-[#7F7F7F]"
                                    }`}
                                />
                            </div>
                        </div>
                        {display ?
                            <>
                                <div
                                    id="mydiv"
                                    style={{marginBottom: (num_multi_search > 1 ? num_multi_search : dynamicMarginBottom()) * 7}}
                                    className="bg-white py-0 h-[25px] mt-2 flex justify-between items-center relative">
                                    <div className="p-0 bg-transparent w-auto">
                                        <p

                                            onClick={handleCustomDropdown}
                                            tabIndex={0}
                                            className="btn pl-3 bg-transparent hover:bg-transparent text-black border-0"
                                        >
                                            {
                                                customDropdown ? <FaCaretUp/> : <FaCaretDown/>
                                            }
                                        </p>
                                        {customDropdown && (
                                            <div className="custom-dropdown bg-white p-2 rounded">
                                                <div className="flex ">
                                                    <div dir="rtl" className="w-1/2 float-right">
                                                        <label style={{fontSize: 11}}
                                                               className="text-black font-bold text-right">&nbsp;&nbsp;&nbsp;קרנות
                                                            בלבד </label>
                                                        {/*<label className="text-black">Months</label>*/}
                                                        {dates['months'].map((item, index) => (
                                                            <div key={index}
                                                                 className="flex mb-3"
                                                            >

                                                                <input
                                                                    value={index}
                                                                    type="checkbox"
                                                                    checked={checkedValuesMonths[index]}
                                                                    onChange={handleCheckMonth}
                                                                    className="mr-1"
                                                                    disabled={checkDisable(num_multi_search, secType)}
                                                                />

                                                                <label className="text-black text-sm">
                                                                    &nbsp;{mapEnglishToHebrew(convertDateFormat(item[0]).split(" ")[0]) + " " + convertDateFormat(item[0]).split(" ")[1]}
                                                                </label>

                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div dir="rtl" className="w-1/2 float-right">
                                                        <label style={{fontSize: 11}}
                                                               className="text-black font-bold"> כל הקופות
                                                            והקרנות</label>
                                                        {/*<label className="text-black">Quarters</label>*/}
                                                        {dates['quarters'].map((item, index) => (
                                                            <div key={index}
                                                                 className="flex mb-3 ">

                                                                <input
                                                                    value={index}
                                                                    type="checkbox"
                                                                    checked={checkedValuesQuarter[index]}
                                                                    onChange={handleCheckQuarter}
                                                                    className="mr-1"
                                                                    disabled={checkDisable(num_multi_search, secType)}
                                                                />
                                                                <label className="text-black text-sm">
                                                                    &nbsp;{getQuarterYear(item[0])}
                                                                </label>

                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                {/*<button onClick={handleCheckChange}*/}
                                                {/*        className="btn btn-sm btn-primary text-center m[33px]">Update Data*/}
                                                {/*</button>*/}
                                            </div>
                                        )}
                                    </div>
                                    <p className="text-xs text-black p-2 font-semibold">
                                        {getYearWithQuarter(dates['quarters'][0])}
                                    </p>
                                </div>

                                {labels_data.map((row, ind) => (
                                    <table
                                        className="w-full "
                                        style={getTableStyle(num_quarters, row[0])}
                                        key={ind}

                                    >
                                        <tbody>
                                        { isGroupChart()?
                                            <tr
                                                style={{
                                                    height: 20,
                                                    color: ""
                                                }}
                                                className=" w-full "
                                            >
                                                <td dir="rtl" className="text-black text-xs font-black text-right "
                                                    title={allLabelsNames[row[0]]}
                                                    colSpan={3}>
                                                  <span
                                                      style={{
                                                          backgroundColor: "white",
                                                      }}
                                                  >
                                                      {allLabelsNames[row[0]].substring(0, 33)}
                                                  </span>
                                                </td>
                                            </tr> : ""
                                        }
                                        <tr
                                            style={getRowStyle(row[0], allQuarterNames[0])}
                                            className=" w-full table_row"
                                            onClick={() => handleRowClick(row, 0)}
                                        >
                                            < td className="w-[30%] font-bold whitespace-nowrap">
                                                {rapnum(row[1][0]).toString()}{selected_data_column === "sum_par_percentage" ? " %" : ""}

                                            </td>
                                            {isGroupChart() ?
                                                <td dir="rtl" className="text-right font-black w-[70%] "
                                                    title={allQuarterNames[0]}>
                                                    {allQuarterNames[0].substring(0, 27)}
                                                </td>
                                                :
                                                <td dir="rtl"
                                                    className="text-white w-[70%] text-xs font-black text-right whitespace-nowrap "
                                                    title={allLabelsNames[row[0]]}
                                                    colSpan={3}
                                                >

                                                    {allLabelsNames[row[0]].substring(0, 20)}
                                                    {
                                                        column2 === "sectors" ?
                                                            <span title="פתח בחיפוש חדש"
                                                                  className="text-right float-left cursor-pointer"
                                                                  onClick={(e) => {
                                                                      e.stopPropagation();
                                                                      sec_id_search(row[0]);
                                                                  }}
                                                            >
                                                            {"(---)"}
                                                            </span>
                                                            :
                                                            ""
                                                    }
                                                </td>
                                            }
                                        </tr>
                                        {row[1].slice(1).map((value, index) => (
                                            <tr
                                                style={getRowStyle(row[0], allQuarterNames[index + 1])}
                                                className="w-full table_row"
                                                key={index}
                                                onClick={() => handleRowClick(row, index + 1)}
                                            >
                                                <td className="font-bold">
                                                    {rapnum(value)} {selected_data_column === "sum_par_percentage" ? " %" : ""}
                                                </td>
                                                <td dir="rtl" className="text-right font-black whitespace-nowrap"
                                                    title={allQuarterNames[index + 1]}>
                                                    {allQuarterNames[index + 1].substring(0, 27)}
                                                </td>

                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                ))}
                            </>
                            : ""
                        }
                    </div>
                </div>

            </div>
        </div>
    )
        ;
};

export default Charts;
