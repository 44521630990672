import React, {useCallback, useEffect, useState, useRef} from "react";
import PropTypes from "prop-types";
import "./multiRangeSlider.css";

const MultiRangeSlider = ({lowerLimit, currentLower, currentUpper, upperLimit, onChange, actual, setOnHover}) => {
    const [minVal, setMinVal] = useState(currentLower);
    const [maxVal, setMaxVal] = useState(currentUpper);
    const minValRef = useRef(currentLower);
    const maxValRef = useRef(currentUpper);
    const range = useRef(null);

    const getPercent = useCallback(
        (value) => Math.round(((value - lowerLimit) / (upperLimit - lowerLimit)) * 100),
        [lowerLimit, upperLimit]
    );
    const onMouseEnter = e => {
        setOnHover(true)
    };

    const onMouseLeave = e => {
        setOnHover(false)
    };
    useEffect(() => {
        const minPercent = getPercent(minVal);
        const maxPercent = getPercent(maxValRef.current);

        if (range.current) {
            range.current.style.left = `${minPercent}%`;
            range.current.style.width = `${maxPercent - minPercent}%`;
        }
    }, [minVal, getPercent]);

    useEffect(() => {
        const minPercent = getPercent(minValRef.current);
        const maxPercent = getPercent(maxVal);

        if (range.current) {
            range.current.style.width = `${maxPercent - minPercent}%`;
        }
    }, [maxVal, getPercent]);

    useEffect(() => {
        onChange({min: minVal, max: maxVal});
    }, [minVal, maxVal, onChange]);

    return (
        <div className="container_">
            <input
                type="range"
                min={lowerLimit}
                max={upperLimit}
                step={0.01}
                value={minVal}
                onChange={(event) => {
                    const value = Math.min(Number(event.target.value), maxVal - 0.01);
                    setMinVal(value);
                    minValRef.current = value;
                }}
                className="thumb thumb--left"
                style={{zIndex: minVal > upperLimit - 0.1 && "5"}}
            />
            <input
                type="range"
                min={lowerLimit}
                max={upperLimit}
                step={0.01}
                value={maxVal}
                onChange={(event) => {
                    const value = Math.max(Number(event.target.value), minVal + 0.01);
                    setMaxVal(value);
                    maxValRef.current = value;
                }}
                className="thumb thumb--right"
            />

            <div className="slider">
                <div className="slider__track">
                    <div
                        onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
                        className="red-dot"
                        style={{left: `${getPercent(actual) + 1.8}%`}} // Set the position of the red dot
                    />
                </div>

                <div ref={range} className="slider__range"/>
                <div className="slider__left-value">{minVal.toFixed(2)}</div>
                <div className="slider__right-value">{maxVal.toFixed(2)}</div>
                <div className="slider__left-value_min">{lowerLimit.toFixed(2)}</div>
                <div className="slider__right-value_max">{upperLimit.toFixed(2)}</div>
            </div>
        </div>
    );
};

MultiRangeSlider.propTypes = {
    lowerLimit: PropTypes.number.isRequired,
    upperLimit: PropTypes.number.isRequired,
    currentLower: PropTypes.number.isRequired,
    currentUpper: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
};

export default MultiRangeSlider;
