import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import Search from './components/search';
import TopBar from './components/topbar';
import Login from './components/pages/login/login';
import SignUp from './components/pages/signup/signup';
import Holdings from "./components/holdings";
import Cookies from 'js-cookie';

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [activeComponent, setActiveComponent] = useState('search');
    useEffect(() => {
        const token = Cookies.get('accessToken');
        if (token) {
            setIsLoggedIn(true);
        }
    }, [isLoggedIn]);
    const handleLogin = () => {
        setIsLoggedIn(true);
        setActiveComponent('search');
    };
    const handleLogout = () => {
        setActiveComponent('login');
        setIsLoggedIn(false);
        // Clear out authentication-related cookies or local storage items
        Cookies.remove('accessToken');
        Cookies.remove('refreshToken');
        Cookies.remove('email');
        // ... remove other cookies or local storage items if necessary
    };

    return (
        <Router>
            <div className="App">
                <TopBar
                    isLoggedIn={isLoggedIn}
                    onSearchClick={() => setActiveComponent('search')}
                    onLoginClick={() => setActiveComponent('login')}
                    onLogoutClick={handleLogout}
                />
                <main className="main">
                    <Routes>
                        <Route
                            path="/"
                            element={isLoggedIn ? (
                                <>
                                    <Holdings/>
                                    <Search handle_logout={handleLogout}/>
                                </>
                            ) : (
                                <Navigate to="/login"/>
                            )}
                        />

                        {/*<Route*/}
                        {/*    path="/"*/}
                        {/*    element={(*/}
                        {/*        <>*/}
                        {/*            <Holdings/>*/}
                        {/*            <Search/>*/}
                        {/*        </>*/}
                        {/*    )}*/}
                        {/*/>*/}

                        <Route
                            path="/login"
                            element={
                                !isLoggedIn ? (
                                    <Login handleLogin={handleLogin}/>
                                ) : (
                                    <Navigate to="/"/>
                                )}
                        />
                        <Route
                            path="/signup"
                            element={
                                !isLoggedIn ? (
                                    <SignUp handleLogin={handleLogin}/>
                                ) : (
                                    <Navigate to="/search"/>
                                )}
                        />
                        <Route
                            path="*"
                            element={<Navigate to="/search"/>}
                        />
                    </Routes>
                </main>
            </div>
        </Router>
    );
}

export default App;
