import React from "react";
import ReactApexChart from "react-apexcharts";

import './style.css'
import { convertDateFormat, mapEnglishToHebrew} from '../utils';
const ApexBar = React.memo(({data, categories}) => {
    const titles = {
        0:"קרנות נאמנות",
        1:"קרנות מחקות וסל",
        2:"קרנות נאמנות",
        3:"קרנות מחקות וסל"
    }
    const my_date = (short_date) => {
        short_date = mapEnglishToHebrew(short_date.split(" ")[0])+" "+short_date.split(" ")[1]
        return short_date
    }
    const options = {
        chart: {
            type: "bar",
            stacked: true,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                dataLabels: {
                    total: {
                        enabled: true,
                        offsetX: 0,
                        style: {
                            fontSize: "13px",
                            color: "#fff",
                            fontWeight: 900,
                        },
                    },
                },
            },
        },
        stroke: {
            width: 0,
            colors: ["#fff"],
        },
        colors: ["#84B547", "#7F7F7F"],
        xaxis: {
            categories: categories,
            show:true,
            labels: {
                formatter: function (val) {
                    if (val){
                        const short_date = convertDateFormat(val)
                        if (short_date.length === 0){
                            return short_date
                        }
                        return mapEnglishToHebrew(short_date.split(" ")[0])+" "+short_date.split(" ")[1];
                    }else{
                        return val
                    }

                },
                style: {
                    colors: "#fff",
                },
                rotate: 50
            },
        },
        yaxis: {
             show: false,
            title: {
                text: undefined,
            },
            reversed: false,
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val;
                },
            },
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              // Customize the tooltip content with a card-like structure
                console.log(series)
                console.log(seriesIndex)
                console.log(dataPointIndex)
                console.log(w)
              return (
                `<div class="custom-tooltip">
                  <div style="background-color: ${w.config.colors[seriesIndex]}" class="tooltip-header text-center ">
                    <div class="tooltip-title">${titles[seriesIndex]}</div>
                  </div>
                  <div class="tooltip-content text-right">
                    <div class="data-point text-right">${ my_date(convertDateFormat(w.config.xaxis.categories[dataPointIndex]))}</div>
                    <div class="value text-right">${series[seriesIndex][dataPointIndex].toLocaleString('en-US', { maximumFractionDigits: 0 })}</div>
                  </div>
                </div>`
              );
            },
        },

        fill: {
            opacity: 1,
        },
        legend: {
            position: "top",
            horizontalAlign: "right",
            inverseOrder: true,
            offsetX: 40,
            markers: {
                fillColors: ["#84B547", "#7F7F7F"],
            },
            labels: {},
            formatter: function (seriesName){
                return mapEnglishToHebrew(seriesName)
            }
        },

        grid: {
            borderColor: "#2f2f2f",
            strokeDashArray: 0,
            position: "back",
            xaxis: {
                lines: {
                    show: false,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
            row: {
                colors: undefined,
                opacity: 0.5,
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (value) {
                if (value >= 1e9) {
                    return (value / 1e9).toFixed(2) + ' B';
                } else if (value >= 1e6) {
                    return (value / 1e6).toFixed(2) + ' M';
                } else if (value >= 1e3) {
                    return (value / 1e3).toFixed(2) + ' K';
                } else if (value < 1) {
                    value = value.toFixed(2)
                } else {
                    value = value.toFixed(2)
                }
                return `${value}`;
            },
        },
    }

    return (
        <div id="chart" className=" min-h-full text-black first">
            <ReactApexChart
                options={options}
                series={data}
                type="bar"
                height="100%"
            />
        </div>
    );
})

export default ApexBar;
