import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Modal from './Modal';
import Swal from "sweetalert2";  // Adjust the path accordingly.

const LoginPage = ({handleLogin}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [forgotPassword, setForgotPassword] = useState(false);
    const [resetEmail, setResetEmail] = useState('');
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    const handleModalSubmit = async (data) => {
         try {
            const response = await axios.post('/accounts/licence-activation/', data);
            if (response.status === 200) {
                console.log("Form Data Submitted Successfully:", response.data);
            } else  {
                console.log("Error submitting form data:", response.data);
                    Swal.fire({
                    title: "הטופס נשלח בהצלחה",
                    text: "אנו ניצור איתך קשר באמצעות דואר אלקטרוני או טלפון בהקדם,",
                    icon: 'info',
                    // confirmButtonText: 'Close'
                    confirmButtonText: 'Close'
                })
                }
            } catch (error) {
                console.error("There was an error submitting the form data:", error);
            }
    };
    const handleUsernameChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleForgotPassword = () => {
        setForgotPassword(true);
    };

    const handleResetEmailChange = (e) => {
        setResetEmail(e.target.value);
    };

    const handleSendResetEmail = () => {
        // Implement logic to send a secure email for password reset
        console.log('Sending reset email to:', resetEmail);
        setForgotPassword(false); // Reset the state after sending the email
    };

    const handleSignUpClick = () => {
        navigate('/signup');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate inputs
        if (!email || !password) {
            setError('Both email and password are required.');
            return;
        }

        // Email validation regex (basic pattern matching for an email)
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        // Additional validations can go here...

        try {
            const response = await axios.post('/accounts/login/', {
                email: email,
                password: password
            });

            console.log(response)
            // Check response status and data
            if (response.status === 200) {

                const data = response.data;

                Cookies.set('email', email);
                Cookies.set('refreshToken', data.refresh);
                Cookies.set('accessToken', data.access);

                // Route to home after successful registration
                navigate('/');
                handleLogin()
                setError('');
            } else {
                setError('שם משתמש או סיסמה אינם נכונים');
            }
        } catch (error) {
            console.log(error.response, "response werfwsfwerwerwnernwe r we rwe")
            if (error.response && error.response.status === 401) {
                setError(error.response.data.detail);

            } else if (error.response && error.response.status === 403) {  // 403 is typically "Forbidden"
                setShowModal(true);
            } else {
                setError('An error occurred while trying to login. Please try again later.');
            }
        }
    };

    return (
        <div dir="rtl" className="flex h-[80vh] items-center justify-center">
            <form style={{backgroundColor: '#1f1f1f', fontFamily: "open sans hebrew"}}
                  className="p-8 shadow-md rounded-md rounded-xl text-white sm:w-96" onSubmit={handleSubmit}>
                <h2 className="text-2xl text-center font-bold mb-6">התחבר</h2>
                {forgotPassword ? (
                    <>
                        <p className="mb-4">שכחת את הסיסמה?</p>
                        <p className="mb-4">הזן את כתובת המייל באמצעותה נרשמת לאתר ואנו נשלח לך מייל מאובטח לשינוי
                            הסיסמה</p>
                        <div className="mb-4">
                            <label htmlFor="reset-email" className="block text-sm font-bold mb-2">
                                מייל
                            </label>
                            <input
                                type="email"
                                id="reset-email"
                                className="text_box_color w-full p-2  rounded-md "
                                value={resetEmail}
                                onChange={handleResetEmailChange}
                                required
                                onInvalid={(e) => e.currentTarget.setCustomValidity('כתובת המייל אינה תקינה')}

                            />
                        </div>
                        <button
                            type="button"
                            onClick={handleSendResetEmail}
                            className="w-full orange_color text-white p-2 rounded-md hover:bg-orange-600"
                        >
                            שלח
                        </button>
                    </>
                ) : (
                    <>
                        <div className="mb-4">
                            <label htmlFor="username" className="block text-sm font-bold mb-2">
                                מייל
                            </label>
                            <input
                                type="email"
                                id="username"
                                className="w-full p-2  rounded-md text_box_color"
                                value={email}
                                onChange={handleUsernameChange}
                                required
                                onInvalid={(e) => e.currentTarget.setCustomValidity('כתובת המייל אינה תקינה')}
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="password" className="block  text-sm font-bold mb-2">
                                סיסמה
                            </label>
                            <div className="relative">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    className="w-full p-2  rounded-md text_box_color"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    required
                                />
                                <button
                                    type="button"
                                    className="absolute top-2 left-4 text-gray-500"
                                    onClick={handleTogglePasswordVisibility}
                                >
                                    {showPassword ? 'הסתר' : 'הצג'}
                                </button>
                            </div>
                        </div>
                        <div className="orange_font mb-2 text-sm cursor-pointer" onClick={handleForgotPassword}>
                            שכחת את הסיסמה?
                        </div>
                        {error && <p className="text-white mb-4">{error}</p>}
                        <button
                            type="submit"
                            className="w-full orange_color text-white p-2 rounded-md hover:bg-orange-600"
                        >
                            התחבר
                        </button>
                    </>
                )}
                <p className="mt-4">
                    אין לך עדיין חשבון?
                    <a className="orange_font" onClick={handleSignUpClick}> הרשם</a>
                </p>
                <p className="mt-2">
                    <a href="#privacy" className="orange_font">
                        מדיניות האתר
                    </a>
                </p>
            </form>
            <Modal
                show={showModal}
                onClose={() => setShowModal(false)}
                onSubmit={handleModalSubmit}
            />
        </div>

    );
};

export default LoginPage;
