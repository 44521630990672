import React, {useEffect, useState} from "react";
import DrorLoader from "../charts/drorLoader/DrorLoader";
import {FaTimes} from "react-icons/fa";
import MultiRangeSlider from "./components/multiRangeSlider/MultiRangeSlider"
import MultiRangeSliderSmall from "./components/multiRangeSlider/MultiRangeSliderSmall"
import {FiArrowLeft, FiArrowRight} from "react-icons/fi";
import {IoCloseOutline} from "react-icons/io5";
import axios from 'axios';
import Charts from "../charts";
import "./rangeslider.css"
import {formatNumber} from "../utils"
import Swal from 'sweetalert2'
import CryptoJS from 'crypto-js';
import {useNavigate} from "react-router-dom";
import Cookies from 'js-cookie';

const Search = ({handle_logout}) => {
        const [activeClass, setActiveClass] = useState("hidden");
        // let activeClass = "hidden"

        const cancel = (e) => {
            e.preventDefault();
            setActiveClass("hidden");

        };
        const toggleSearch = () => {
            // // console.log("toggle 1 triggerd")
            setActiveClass(activeClass === "hidden" ? "block" : "hidden");
        };

        function toggleSearch2(myclass) {
            // console.log("toggle 2 triggerd")
            setActiveClass(myclass);
        };

        const [visibleSearchPopup, setVisibleSearchPopup] = useState(false);
        const [visibleSuggestions, setVisibleSuggestions] = useState(false);

        const [searchedTerms, setSearchedTerms] = useState([]);

        const [lastField, setLastField] = useState(false);

        const [lastSearches, setLastSearches] = useState([]);
        const [favSearches, setFavSearches] = useState([]);

        const [searchSuggestions, setSearchSuggestions] = useState([]);
        const [labelsDataNum, setLabelsDataNum] = useState(670)
        const [relatedCorpsIds, setRelatedCorpsIds] = useState([])
        const [secTypeSub, setSecTypeSub] = useState([])
        const [secType, setSecType] = useState([])
        const [secId, setSecId] = useState([])
        const [sector, setSector] = useState([])
        const [sectorSub, setSectorSub] = useState([])

        //  Right chart data
        const [seriesData, setSeries] = useState([])

        // Table data
        const [labelsData, setLabelsData] = useState([])
        const [allLabelsNames, setAllLabelsNames] = useState({})
        const [allQuarterNames, setAllQuarterNames] = useState({})

        // Left Charts Data
        const [barMonthlyData, setbarMonthlyData] = useState([])
        const [barQuarterlyData, setbarQuarterlyData] = useState([])
        const [barMonthlyCategories, setbarMonthlyCategories] = useState([])
        const [barQuarterlyCategories, setbarQuarterlyCategories] = useState([])


        const [grandSum, setGrandSum] = useState(0)
        const [selectedRowId, setSelectedRowId] = useState('none');
        const [display, setDisplay] = useState(false);
        const [selectedDataColumn, setSelectedDataColumn] = useState("sum_market_cap")

        const [dates, setDates] = useState({'months': [], 'quarters': []})
        const [checkedValuesMonths, setCheckedValuesMonths] = React.useState([true, false, false, false, false, false, false, false, false, false, false, false]);
        const [checkedValuesQuarter, setCheckedValuesQuarter] = React.useState([true, false, false, false, false, false, false, false, false, false, false, false]);
        const checkedValuesFalse = [false, false, false, false, false, false, false, false, false, false, false, false];
        const checkedValuesDefault = [true, false, false, false, false, false, false, false, false, false, false, false];
        const [lagends, setLagends] = React.useState([]);
        const [num_quarters, setNumQuarters] = React.useState(1);
        const [loading, setLoading] = useState(false);
        const [loadingLeft, setLoadingLeft] = useState(false);
        const [customDropdown, setCustomDropdown] = useState(false);
        const [keywords, setKeywords] = useState([])
        const [rightChartCatagories, setRightChartCatagories] = useState([])
        const [column, setColumn] = useState("sec_id")
        const [column2, setColumn2] = useState("holders")
        const [colTab2, setColTab2] = useState("כותרת")
        const [onHoverCap, setOnHoverCap] = useState(false)
        const [onHoverDur, setOnHoverDur] = useState(false)
        const oneOrTwo = '1'
        const [toShowDuration, setToShowDuration] = useState(true)
        const [toShowCap, setToShowCap] = useState(true)
        const [currentFavouriteKey, setCurrentFavouriteKey] = useState(-1)
        const [starActive, setStarActive] = useState(false)
        const [filters, setFilters] = useState({
            'market_cap_range': [0, 0, 0, 0],
            's_duration_range': [0, 0, 0, 0],
            'sec_type_sub': [],
            'sector_sub': [],
            'atzmada': [],
            'rating': [],
            'actual_cap': 0,
            'actual_dur': 0,
            'sector_sub_legend': {},
            'atzmada_legend': {},
            'sec_type_sub_legend': {},
            'foreign_rate_legend': {}
        })
        const filters_start = {
            'market_cap_range': [0, 0, 0, 0],
            's_duration_range': [0, 0, 0, 0],
            'sec_type_sub': [],
            'sector_sub': [],
            'atzmada': [],
            'rating': [],
            'actual_cap': 0,
            'actual_dur': 0,
            'sector_sub_legend': {},
            'atzmada_legend': {},
            'sec_type_sub_legend': {},
            'foreign_rate_legend': {}
        }
        const [customFilters, setCustomFilters] = useState({
            'market_cap_range': [0, 0, 0, 0],
            's_duration_range': [0, 0, 0, 0],
            'sec_type_sub': [],
            'sector_sub': [],
            'atzmada': [],
            'foreign_rate': [],
            'actual_cap': 0,
            'actual_dur': 0,
            'sector_sub_legend': {},
            'atzmada_legend': {},
            'sec_type_sub_legend': {},
            'foreign_rate_legend': {}
        })

        const custom_filters_start = {
            'market_cap_range': [0, 0, 0, 0],
            's_duration_range': [0, 0, 0, 0],
            'sec_type_sub': [],
            'sector_sub': [],
            'atzmada': [],
            'foreign_rate': [],
            'actual_cap': 0,
            'actual_dur': 0,
            'sector_sub_legend': {},
            'atzmada_legend': {},
            'sec_type_sub_legend': {},
            'foreign_rate_legend': {}
        }

        const [selectedCustomFilters, setSelectedCustomFilters] = useState({
            'sec_type_sub': null,
            'sector_sub': null,
            'market_cap_range': null,
            's_duration_range': null,
        })
        const modal_description = {
            'holders': ['באפשרותך לחפש קבוצות של ני”ע על פי פרמטרים נבחרים', 'חיפוש מתקדם', ''],
            'non_holders': ['רשימת מנהלים אשר מחזיקים בני”ע דומים לני”ע הנבחר אך אינם מחזיקים בנייר הנבחר', 'שאינם מחזיקים', 'ני”ע דומים על פי הרשימה מטה'],
            'holders_similar': ['אחזקות מצרפיות עבור כל המנהלים אשר מחזיקים בני”ע דומים לני”ע הנבחר כולל הני”ע הנבחר', 'אחזקות בני”ע דומים', 'ני”ע דומים על פי הרשימה מטה'],
            'sectors': ['רשימת ני”ע אשר דומים לני”ע הנבחר כולל הני”ע הנבחר', 'רשימת ני”ע דומים', 'ני”ע דומים על פי הרשימה מטה']
        }

        const [isCustomFilter, setIsCustomFilter] = useState(true)
        const [secIdDataList, setSecIdDataList] = useState([])

        const [relatedSecurities, setRelatedSecurities] = useState([])
        const [related_securities_legend, set_related_securities_legend] = useState({
            'related_securities_sector_sub_legend': {},
            'related_securities_sector_legend': {},
            'related_securities_sec_type_sub_legend': {},
            'related_securities_sec_type_legend': {}
        })

        const desc = 'באפשרותך לחפש קבוצות של ני”ע על פי פרמטרים נבחרים'
        const desc_ = ''
        const title_ = 'חיפוש מתקדם'
        const [description, setDescription] = useState(desc)
        const [description_, setDescription_] = useState(desc_)
        const [title_modal, setTitleModal] = useState(title_)
        const [left_chart_msg, setLeftChartMsg] = useState(false)
        const [appLoading, setAppLoading] = useState(true);


        const applyFilters = () => {
            // console.log(filters)
            if (secTypeSub.length !== 0) {
                fetchRightChartData(secId, secTypeSub, selectedDataColumn).then(r => {
                    setActiveClass('hidden')
                })
            }

        }

        const setrangecap = (min, max) => {
            if (min !== filters['market_cap_range'][1] || max !== filters['market_cap_range'][2]) {
                setFilters({
                    ...filters,
                    'market_cap_range': [filters['market_cap_range'][0], min, max, filters['market_cap_range'][3]]
                })

            }
        }

        const setrangecap_custom = (min, max) => {
            if (min !== customFilters['market_cap_range'][1] || max !== customFilters['market_cap_range'][2]) {
                setCustomFilters({
                    ...customFilters,
                    'market_cap_range': [customFilters['market_cap_range'][0], min, max, customFilters['market_cap_range'][3]]
                })

            }
        }

        const setrangeduration = (min, max) => {
            if (min !== filters['s_duration_range'][1] || max !== filters['s_duration_range'][2]) {
                setFilters({
                    ...filters,
                    's_duration_range': [filters['s_duration_range'][0], min, max, filters['s_duration_range'][3]]
                })
            }
        }

        const handleCheckMonth = (event) => {
            const {value, checked} = event.target;
            let ls_qua = [...checkedValuesQuarter]
            let ls_mon = [...checkedValuesMonths]
            ls_mon[value] = checked

            if (countTrueValues(ls_mon, 2)) {
                if (countTrueValues(checkedValuesQuarter, 0)) {
                    ls_qua = checkedValuesFalse

                }
            } else {
                ls_qua = checkedValuesDefault
                ls_mon = checkedValuesDefault
            }
            setCheckedValuesQuarter(ls_qua)
            setCheckedValuesMonths(ls_mon)
            if (secTypeSub.length !== 0) {
                fetchRightChartData(secId, secTypeSub, selectedDataColumn, ls_mon, ls_qua).then(r => {
                })
            }
            setColumn2('holders')
            setColTab2('holders')
            handleCustomDropdown()
            handleCustomDropdown()

        };

        const handleCheckQuarter = (event) => {
            const {value, checked} = event.target;
            let ls_qua = [...checkedValuesQuarter]
            let ls_mon = [...checkedValuesMonths]
            ls_qua[value] = checked

            if (countTrueValues(ls_qua, 2)) {
                if (countTrueValues(ls_mon, 0)) {
                    ls_mon = checkedValuesFalse
                }
            } else {
                ls_qua = checkedValuesDefault
                ls_mon = checkedValuesDefault
            }
            setCheckedValuesQuarter(ls_qua)
            setCheckedValuesMonths(ls_mon)


            if (secTypeSub.length !== 0) {
                fetchRightChartData(secId, secTypeSub, selectedDataColumn, ls_mon, ls_qua).then(r => {
                })
            }
            setColumn2('holders')
            setColTab2('holders')
            handleCustomDropdown()

        };

        const countTrueValues = (list, num = 0) => {

            let count = 0;
            for (let i = 0; i < list.length; i++) {
                if (list[i] === true) {
                    count++;
                }
            }
            if (count >= num) {
                return true;
            } else {
                return false
            }

        }
        const fetchDataInit = async () => {
            try {
                const response = await axios.get('api/get_dates');
                const response_filters = await axios.get('api/custom_filters/');
                setDates({'months': response.data.months, 'quarters': response.data.quarters});
                console.log(response.data)
                setCustomFilters(
                    {
                        ...customFilters,
                        'sec_type_sub': response_filters.data.sec_tyep_sub,
                        'sec_type_sub_legend': response_filters.data.sec_type_sub_legend
                    }
                )

                const existing_storage = JSON.parse(localStorage.getItem('stateObject')) || {}
                setNewFavoriteSearches(existing_storage)
                setIsCustomFilter(true)
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setAppLoading(false); // Finish loading regardless of outcome
            }
        };
        useEffect((callback, deps) => {
            fetchDataInit();
        }, []);

        function filter_change(e, witch_select) {

            if (witch_select === 'sec_type_sub') {
                console.log(witch_select)
                console.log(e.target.value)
                const updated_filters = {
                    ...selectedCustomFilters,
                    'sec_type_sub': e.target.value,
                    'sector_sub': null,
                    'market_cap_range': null,
                    's_duration_range': null,
                    'atzmada': null,
                    'foreign_rate': null,
                    'column': "sectors",
                }
                setSelectedCustomFilters(updated_filters)
                setDisplay(false)
                fetch_filters(updated_filters).then(r => {
                    console.log("Ok")
                })
            } else if (witch_select === 'sector_sub') {
                setLoading(true)
                setDisplay(false)
                const updated_filters = {
                    ...selectedCustomFilters,
                    'sector_sub': e.target.value,
                    'market_cap_range': null,
                    's_duration_range': null,
                    'atzmada': null,
                    'foreign_rate': null,
                    'column': "sectors",
                }
                setSelectedCustomFilters(updated_filters)
                setCustomFilters({
                    ...customFilters,
                    'market_cap_range': null,
                    's_duration_range': null,
                })
                fetch_filters(updated_filters).then(r => {
                    console.log("Ok")
                })

                console.log(witch_select)
            } else if (witch_select === 'atzmada') {
                setLoading(true)
                setDisplay(false)
                const updated_filters = {
                    ...selectedCustomFilters,
                    'atzmada': e.target.value,
                    'foreign_rate': null,
                    'market_cap_range': null,
                    's_duration_range': null,
                    'column': "sectors",
                }
                setSelectedCustomFilters(updated_filters)
                setCustomFilters({
                    ...customFilters,
                    'market_cap_range': null,
                    's_duration_range': null,
                })
                fetch_filters(updated_filters).then(r => {
                    console.log("Ok")
                })

                console.log(witch_select)
            } else if (witch_select === 'foreign_rate') {
                setLoading(true)
                setDisplay(false)
                const updated_filters = {
                    ...selectedCustomFilters,
                    'foreign_rate': e.target.value,
                    'market_cap_range': null,
                    's_duration_range': null,
                    'column': "sectors",
                }
                setSelectedCustomFilters(updated_filters)
                setCustomFilters({
                    ...customFilters,
                    'market_cap_range': null,
                    's_duration_range': null,
                })
                fetch_filters(updated_filters).then(r => {
                    console.log("Ok")
                })

                console.log(witch_select)
            }

        }

        function custom_filters_onclick() {
            setDisplay(false)
            setLoading(true)
            const updated_filters = {
                ...selectedCustomFilters,
                'market_cap_range': customFilters.market_cap_range,
                's_duration_range': customFilters.s_duration_range,
                'column': colTab2
            }
            setSelectedCustomFilters(updated_filters)
            console.log(updated_filters)
            fetch_filters_and_display(updated_filters).then(r => {
                console.log("Ok")
            })
            toggleSearch2('hidden')

        }

        const fetch_filters_and_display = async (custom_filters = selectedCustomFilters) => {
            let months = []
            let quarters = []
            checkedValuesMonths.forEach(function (value, index) {
                if (value) {
                    months.push(dates['months'][index][0])

                }
            });
            checkedValuesQuarter.forEach(function (value, index) {
                if (value) {
                    quarters.push(dates['quarters'][index][0])

                }
            });
            if (quarters.length === 0 && months.length === 0) {
                quarters.push(dates['quarters'][0][0])
                months.push(dates['months'][0][0])
            } else if (quarters.length === 1 && months.length === 0) {
                months.push(dates['months'][0][0])
            }
            custom_filters['dates_q'] = quarters
            custom_filters['dates_m'] = months
            setSelectedCustomFilters(custom_filters)
            const response_filters = await axios.post('api/custom_filters/', custom_filters);
            setCustomFilters({
                ...customFilters,
                'sec_type_sub': response_filters.data.sec_type_sub,
                'sector_sub': response_filters.data.sector_sub,
                'market_cap_range': response_filters.data.market_cap_range,
                's_duration_range': response_filters.data.s_duration_range,
                'atzmada': response_filters.data.atzmada,
                'foreign_rate': response_filters.data.foreign_rate,
                'sector_sub_legend': response_filters.data.sector_sub_legend,
                'atzmada_legend': response_filters.data.atzmada_legend,
                'sec_type_sub_legend': response_filters.data.sec_type_sub_legend,
                'foreign_rate_legend': response_filters.data.foreign_rate_legend,
            })
            if ('series_sum' in response_filters.data) {
                if (response_filters.data.series.length !== 0) {
                    setSeries(response_filters.data.series)
                    let count = custom_filters['dates_q'].length
                    if (custom_filters['dates_q'].length > 1) {
                        count = custom_filters['dates_q'].length + 1
                    } else if (custom_filters['dates_m'].length > 1) {
                        count = custom_filters['dates_m'].length + 1
                    } else {
                        count = 1
                    }
                    setLabelsDataNum((response_filters.data.series_sum.length * count * 20) + (response_filters.data.series_sum.length * 11) + 110)
                }

                setLabelsData(response_filters.data.series_sum)
                setAllLabelsNames(response_filters.data.all_labels_names)
                setAllQuarterNames(response_filters.data.list_quarter_names)
                setGrandSum(response_filters.data.all_values_sum)
                setLagends(response_filters.data.legands)
                setNumQuarters(custom_filters['dates_q'].length)
                setRelatedCorpsIds(response_filters.data.related_corps_ids.reverse())
                setSearchSuggestions([]);
                setRightChartCatagories(response_filters.data.series_sum.map((row, ind) => (response_filters.data.all_labels_names[row[0]])))
                setColumn(column)
                setSelectedDataColumn(selectedDataColumn)
                setCheckedValuesMonths(checkedValuesMonths)
                setCheckedValuesQuarter(checkedValuesQuarter)
                setKeywords(keywords)
                setSecType([''])
                setSecId([''])
                setSecTypeSub([custom_filters['sec_type_sub']])
                setSectorSub([custom_filters['sector_sub']])
                setToShowDuration(true)
                setToShowCap(true)
                setColumn2(response_filters.data.column2)
                setColTab2(response_filters.data.column2)
                setDisplay(true)
                setLoading(false)
            } else {
                setLoading(false)
            }
            if ('is_data' in response_filters.data) {
                if (response_filters.data.is_data === null) {
                    setLoading(false)
                    setDisplay(false)
                    // setCustomFilters(custom_filters_start)
                    Swal.fire({
                        title: 'מידע',
                        text: 'אין היסטוריה עבור החיפוש המבוקש',
                        icon: 'info',
                        // confirmButtonText: 'Close'
                        confirmButtonText: 'סגור'
                    })
                }
            }
            console.log(response_filters.data)
        }

        const fetch_filters = async (custom_filters = selectedCustomFilters) => {
            let months = []
            let quarters = []
            checkedValuesMonths.forEach(function (value, index) {
                if (value) {
                    months.push(dates['months'][index][0])

                }
            });
            checkedValuesQuarter.forEach(function (value, index) {
                if (value) {
                    quarters.push(dates['quarters'][index][0])

                }
            });
            if (quarters.length === 0 && months.length === 0) {
                quarters.push(dates['quarters'][0][0])
                months.push(dates['months'][0][0])
            } else if (quarters.length === 1 && months.length === 0) {
                months.push(dates['months'][0][0])
            }
            custom_filters['dates_q'] = quarters
            custom_filters['dates_m'] = months
            setSelectedCustomFilters(custom_filters)
            const response_filters = await axios.post('api/custom_filters/', custom_filters);
            setCustomFilters({
                ...customFilters,
                'sec_type_sub': response_filters.data.sec_type_sub,
                'sector_sub': response_filters.data.sector_sub,
                'market_cap_range': response_filters.data.market_cap_range,
                's_duration_range': response_filters.data.s_duration_range,
                'atzmada': response_filters.data.atzmada,
                'foreign_rate': response_filters.data.foreign_rate,
                'sector_sub_legend': response_filters.data.sector_sub_legend,
                'atzmada_legend': response_filters.data.atzmada_legend,
                'sec_type_sub_legend': response_filters.data.sec_type_sub_legend,
                'foreign_rate_legend': response_filters.data.foreign_rate_legend,
            })
            if ('series_sum' in response_filters.data) {
                if (response_filters.data.series.length !== 0) {
                    setSeries(response_filters.data.series)
                    let count = custom_filters['dates_q'].length
                    if (custom_filters['dates_q'].length > 1) {
                        count = custom_filters['dates_q'].length + 1
                    } else if (custom_filters['dates_m'].length > 1) {
                        count = custom_filters['dates_m'].length + 1
                    } else {
                        count = 1
                    }
                    setLabelsDataNum((response_filters.data.series_sum.length * count * 20) + (response_filters.data.series_sum.length * 11) + 110)
                }
                setLabelsData(response_filters.data.series_sum)
                setAllLabelsNames(response_filters.data.all_labels_names)
                setAllQuarterNames(response_filters.data.list_quarter_names)
                setGrandSum(response_filters.data.all_values_sum)
                setLagends(response_filters.data.legands)
                setNumQuarters(custom_filters['dates_q'].length)
                setRelatedCorpsIds(response_filters.data.related_corps_ids.reverse())
                setSearchSuggestions([]);
                setRightChartCatagories(response_filters.data.series_sum.map((row, ind) => (response_filters.data.all_labels_names[row[0]])))
                setColumn(column)
                setSelectedDataColumn(selectedDataColumn)
                setCheckedValuesMonths(checkedValuesMonths)
                setCheckedValuesQuarter(checkedValuesQuarter)
                setKeywords(keywords)
                setSecType([''])
                setSecId([''])
                setSecTypeSub([custom_filters['sec_type_sub']])
                setSectorSub([custom_filters['sector_sub']])
                setToShowDuration(true)
                setToShowCap(true)
                setColumn2(response_filters.data.column2)
                setColTab2(response_filters.data.column2)
                setDisplay(false)
                setLoading(false)
            } else {
                setLoading(false)
            }
            if ('is_data' in response_filters.data) {
                if (response_filters.data.is_data === null) {
                    setLoading(false)
                    setDisplay(false)
                    // setCustomFilters(custom_filters_start)
                    Swal.fire({
                        title: 'מידע',
                        text: 'אין היסטוריה עבור החיפוש המבוקש',
                        icon: 'info',
                        // confirmButtonText: 'Close'
                        confirmButtonText: 'סגור'
                    })
                }
            }
            console.log(response_filters.data)
        }

        function setNewFavoriteSearches(existing_storage) {

            if (Object.keys(existing_storage).length === 0) {
                // console.log('Favourite Searches are empty');
            } else {
                const keywordsList = [];
                for (const key in existing_storage) {
                    if (existing_storage.hasOwnProperty(key) && existing_storage[key].keywords) {
                        const joinedKeywords = existing_storage[key].keywords.join(' | ');
                        keywordsList.push(joinedKeywords);
                    }
                }
                // console.log(keywordsList)
                setFavSearches(keywordsList)
            }
        }

        const deleteItem = (index) => {
            const newSearches = lastSearches.filter((search, i) => i !== index);
            setLastSearches(newSearches);
        };

        const deleteFavItem = (index) => {
            const newSearches = favSearches.filter((search, i) => i !== index);
            setFavSearches(newSearches);
        };


        const search = document.getElementById("search");

        const fetchData = async (keyword) => {
            try {
                const data = {
                    'keyword': keyword
                }
                const response = await axios.post('api/search/', data);
                setSearchSuggestions(response.data)

            } catch (error) {
                console.error(error);
            }
        };
        const navigate = useNavigate();

        const isLogin = async () => {
            console.log("checking user login")
            try {
                const token = Cookies.get('accessToken');

                const response = await axios.get('accounts/protected', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                console.log(response)
                return response.data;
            } catch (error) {
                if (axios.isAxiosError(error) && error.response?.status === 401) {
                    console.log("Not Login")
                    handle_logout()
                } else {
                    // Handle other errors
                    console.error('An error occurred:', error);
                }
            }
        }

        const fetchRightChartData = async (sec_id,
                                           sec_type_sub,
                                           col_name,
                                           checkedValuesMonths_ = checkedValuesMonths,
                                           checkedValuesQuarter_ = checkedValuesQuarter,
                                           keywords_ = keywords,
                                           sec_type = secType,
                                           column_ = column,
                                           sector_sub_ = sectorSub,
                                           one_or_two = oneOrTwo,
                                           col_tab = column2,
                                           filters_ = filters,
                                           colTab2_ = colTab2
        ) => {
            try {
                isLogin()
                setDisplay(false)
                setLoading(true)
                setToShowDuration(false)
                setToShowCap(false)
                setIsCustomFilter(false)
                setSeries([])
                setLabelsData([])
                setbarMonthlyCategories([])
                setbarMonthlyData([])
                setbarQuarterlyData([])
                setbarQuarterlyCategories([])
                setGrandSum(0)
                setLeftChartMsg(false)
                setSecIdDataList([])
                // setRelatedCorpsIds([])
                let months = []
                let quarters = []
                checkedValuesMonths_.forEach(function (value, index) {
                    if (value) {
                        months.push(dates['months'][index][0])

                    }
                });
                checkedValuesQuarter_.forEach(function (value, index) {
                    if (value) {
                        quarters.push(dates['quarters'][index][0])

                    }
                });
                if (quarters.length === 0 && months.length === 0) {
                    quarters.push(dates['quarters'][0][0])
                    months.push(dates['months'][0][0])
                } else if (quarters.length === 1 && months.length === 0) {
                    months.push(dates['months'][0][0])
                }

                const data = {
                    "sec_type_sub": sec_type_sub,
                    "sec_id": sec_id,
                    "selected_data_column": col_name,
                    "dates_m": months,
                    "dates_q": quarters,
                    "keywords": keywords_,
                    'sec_type': sec_type,
                    'column': column_,
                    "sector_sub": sector_sub_,
                    "one_or_two": one_or_two,
                    "filters": filters_,
                    "column2": col_tab,
                    "col_tab_2": colTab2_
                }


                axios.post('api/search_step_2/', data)
                    .then((response) => {
                        // console.log(response);
                        if (response.data.length === 0) {
                            alert("אין היסטוריה עבור החיפוש המבוקש")
                            setLoading(false)
                            // setDisplay(true)


                        } else {
                            if (response.data.series.length !== 0) {
                                setSeries(response.data.series)
                                let count = quarters.length
                                if (quarters.length > 1) {
                                    count = quarters.length + 1
                                } else if (months.length > 1) {
                                    count = months.length + 1
                                } else if (sec_id.length > 1) {
                                    count = sec_id.length + 1
                                } else {
                                    count = sec_id.length
                                }
                                setLabelsDataNum((response.data.series_sum.length * count * 20) + (response.data.series_sum.length * 11) + 110)
                            }
                            console.log(response.data)
                            setLabelsData(response.data.series_sum)
                            setAllLabelsNames(response.data.all_labels_names)
                            setAllQuarterNames(response.data.list_quarter_names)
                            setGrandSum(response.data.all_values_sum)
                            setLoading(false)
                            setLagends(response.data.legands)
                            if (quarters.length === 0){
                                setNumQuarters(months.length)
                            }else{
                                setNumQuarters(quarters.length)
                            }

                            setRelatedCorpsIds(response.data.related_corps_ids.reverse())
                            setSearchSuggestions([]);
                            setRightChartCatagories(response.data.series_sum.map((row, ind) => (response.data.all_labels_names[row[0]])))
                            setColumn(column_)
                            setSelectedDataColumn(col_name)
                            setCheckedValuesMonths(checkedValuesMonths_)
                            setCheckedValuesQuarter(checkedValuesQuarter_)
                            setKeywords(keywords_)
                            setSecType(sec_type)
                            setSecId(sec_id)
                            setSecTypeSub(sec_type_sub)
                            setSectorSub(sector_sub_)
                            setLastField(false)
                            if (response.data.sec_ids_data) {
                                setSecIdDataList(response.data.sec_ids_data)
                            }
                            if (response.data.selected_row_filters) {
                                setFilters(response.data.selected_row_filters)
                                // console.log("_________________________________________________")
                                // console.log(response.data.selected_row_filters.filters_valid)
                                // console.log("_________________________________________________")
                                if (response.data.selected_row_filters.filters_valid === 'false') {
                                    Swal.fire({
                                        title: 'מידע',
                                        text: 'אין היסטוריה עבור החיפוש המבוקש',
                                        icon: 'info',
                                        confirmButtonText: 'OK'
                                    })
                                }
                            } else {
                                setFilters(filters_start)
                            }
                            setRelatedSecurities(response.data.related_securities)
                            set_related_securities_legend(response.data.related_securities_legend)

                            setToShowDuration(true)
                            setToShowCap(true)
                            setColumn2(col_tab)
                            setColTab2(colTab2_)
                            setDisplay(true)
                            const current = {
                                "sec_id": sec_id,
                                "sec_type_sub": sec_type_sub,
                                "col_name": col_name,
                                "checkedValuesMonths_": checkedValuesMonths_,
                                "checkedValuesQuarter_": checkedValuesQuarter_,
                                "keywords_": keywords_,
                                "sec_type": sec_type,
                                "column_": column_,
                                "sector_sub_": sector_sub_,
                                "one_or_two": one_or_two,
                                "col_tab": col_tab,
                                "filters_": filters_,
                                "colTab2_": colTab2_
                            }
                            see_star(current)
                            search.placeholder = "הוסף ני”ע להשוואה";
                        }

                    }, (error) => {
                        // console.log(error);
                        setToShowCap(false)
                        setToShowDuration(false)
                        setFilters(filters_start)
                        setLoading(false)
                        Swal.fire({
                            title: 'אירעה תקלה',
                            text: 'התקלה בטיפול, במידה והתקלה לא נפתרה פנה לצוות support@brokersconnect.co.il התמיכה במייל ',
                            icon: 'error',
                            // confirmButtonText: 'Close'
                            confirmButtonText: 'סגור'
                        })
                        // alert("Ohh: This query is new for me, please discuss it with your developer")
                        setLoading(false)
                    });
            } catch (error) {
                console.error(error);

                // alert("Ohh: Something went wrong, please discuss it with your developer")
                Swal.fire({
                    title: 'אירעה תקלה',
                    text: 'התקלה בטיפול, במידה והתקלה לא נפתרה פנה לצוות support@brokersconnect.co.il התמיכה במייל  ',
                    icon: 'error',
                    // confirmButtonText: 'Close'
                    confirmButtonText: 'סגור'

                })
                 clear_all()


            }
        };

        const searchKeyUp = (e) => {
            if (e.target.value.length > 1) {
                fetchData(e.target.value)
                setVisibleSearchPopup(false);
                setLastField(false);
                setVisibleSuggestions(true);
                setIsCustomFilter(false)
            } else {
                setIsCustomFilter(true)
                setVisibleSearchPopup(true);
                setVisibleSuggestions(false);
                if (isCustomFilter) {
                    clear_all()
                }

            }
        };

        const handleRowClick = async (rowData, index) => {
            setbarMonthlyCategories([])
            setbarMonthlyData([])
            setbarQuarterlyData([])
            setbarQuarterlyCategories([])
            setLoadingLeft(true)

            // // console.log(secId, secTypeSub)
            if (num_quarters > 1) {
                index = 0
                setSelectedRowId(rowData[0].toString())
            } else {
                setSelectedRowId(rowData[0].toString() + allQuarterNames[index])
            }
            const data = {
                "sec_type_sub": secTypeSub[index],
                "sec_id": secId[index],
                "control_corp": rowData[0],
                "selected_data_column": selectedDataColumn
            }
            // setSelectedRowId(rowData[0].toString()+allQuarterNames[index])
            axios.post('api/left_charts/', data)
                .then((response) => {

                    setbarMonthlyData(response.data.data_month)
                    setbarQuarterlyData(response.data.data_quarter)
                    setbarMonthlyCategories(response.data.categories_month)
                    setbarQuarterlyCategories(response.data.categories_quarter)
                    setLoadingLeft(false)
                    setLeftChartMsg(true)
                }, (error) => {
                    clear_all()
                    console.log(error);
                });

        };

        const handleCustomDropdownTop = (event) => {
            let col_name = "sum_market_cap"
            const opt = event.target.value;
            if (opt === "שווי שוק") {
                col_name = 'sum_market_cap'
            } else if (opt === "אחוז מהון רשום") {
                col_name = 'sum_par_percentage'
            } else {
                col_name = "sum_par_value"
            }


            if (secTypeSub.length !== 0) {
                fetchRightChartData(secId, secTypeSub, col_name).then(r => {
                })
            }
            setSelectedDataColumn(col_name)

        };

        const handleSelectionChange = (col, one_two) => {
            // console.log(col)
            // console.log("here i am")
            // setColumn(col)
            if (secTypeSub.length !== 0) {
                fetchRightChartData(
                    secId,
                    secTypeSub,
                    selectedDataColumn,
                    checkedValuesMonths,
                    checkedValuesQuarter,
                    keywords,
                    secType,
                    col,
                    sectorSub,
                    one_two).then(r => {
                })
            }
        }

        const handleSelectionChange2 = (col) => {
            // console.log(col)
            setDescription(modal_description[col][0])
            setDescription_(modal_description[col][2])
            setTitleModal(modal_description[col][1])
            if (secTypeSub.length !== 0) {
                setColTab2(col)
                setColumn2(col)
                console.log("i am here ")
                if (col !== "holders") {
                    toggleSearch2("block")
                } else {
                    toggleSearch2("hidden")

                    fetchRightChartData(
                        secId,
                        secTypeSub,
                        selectedDataColumn,
                        checkedValuesMonths,
                        checkedValuesQuarter,
                        keywords,
                        secType,
                        column,
                        sectorSub,
                        oneOrTwo,
                        col,
                        filters,
                        col
                    ).then(r => {
                        // setColumn2(col)
                        // setColTab2(col)
                    })
                }
            }
        }

        function generateUniqueIdentifier(jsonObject) {
            const jsonString = JSON.stringify(jsonObject);
            return CryptoJS.SHA256(jsonString).toString(CryptoJS.enc.Hex);
        }

        const see_star = (data) => {
            let stateObject = {
                'secId': data['sec_id'],
                'secTypeSub': data['sec_type_sub'],
                'selectedDataColumn': data['col_name'],
                'checkedValuesMonths': data['checkedValuesMonths_'],
                'checkedValuesQuarter': data['checkedValuesQuarter_'],
                'keywords': data['keywords_'],
                'secType': data['sec_type'],
                'column': data['column_'],
                'sectorSub': data['sector_sub_'],
                'oneOrTwo': data['one_or_two'],
                'column2': data['col_tab'],
                'filters': data['filters_'],
                'colTab2': data['colTab2_'],
            }

            const key = generateUniqueIdentifier(stateObject)
            const existing_storage = JSON.parse(localStorage.getItem('stateObject')) || {}
            if (Object.keys(existing_storage).length === 0) {
                setStarActive(false)
            } else {
                if (existing_storage.hasOwnProperty(key)) {
                    setStarActive(true)
                } else {
                    setStarActive(false)
                }
            }
            // console.log(key)

        }

        const getFromLocalStorage = (increment) => {
            const existing_storage = JSON.parse(localStorage.getItem('stateObject')) || {}
            if (Object.keys(existing_storage).length === 0) {
                // console.log('Favourite Searches are empty');
            } else {
                const objects_list = [];
                for (const key in existing_storage) {
                    if (existing_storage.hasOwnProperty(key) && existing_storage[key].keywords) {
                        const joinedKeywords = existing_storage[key]
                        objects_list.push(joinedKeywords);
                    }
                }
                // console.log(objects_list)
                let index = 0;
                if (objects_list.length !== 0) {
                    if (currentFavouriteKey === -1 && increment === -1) {
                        index = objects_list.length - 1
                    } else if (currentFavouriteKey === 0 && increment === -1) {
                        index = objects_list.length - 1
                    } else if (currentFavouriteKey === objects_list.length - 1 && increment === 1) {
                        index = 0
                    } else {
                        index = currentFavouriteKey + increment
                    }
                    let my_object = {}
                    my_object = objects_list[index]
                    fetchRightChartData(
                        my_object.secId,
                        my_object.secTypeSub,
                        my_object.selectedDataColumn,
                        my_object.checkedValuesMonths,
                        my_object.checkedValuesQuarter,
                        my_object.keywords,
                        my_object.secType,
                        my_object.column,
                        my_object.sectorSub,
                        my_object.oneOrTwo,
                        my_object.column2,
                        my_object.filters,
                        my_object.colTab2
                    ).then(r => {
                        setCurrentFavouriteKey(index)
                        setStarActive(true)
                        var result = my_object.keywords.map(function (word) {
                            return [word];
                        });
                        setSearchedTerms(result)
                    })
                } else {
                    setStarActive(false)
                    // console.log("No Favourite is found")
                }

            }
        }

        const activate_star_function = (active) => {
            if (active) {
                console.log("1")
                let stateObject = {
                    'secId': secId,
                    'secTypeSub': secTypeSub,
                    'selectedDataColumn': selectedDataColumn,
                    'checkedValuesMonths': checkedValuesMonths,
                    'checkedValuesQuarter': checkedValuesQuarter,
                    'keywords': keywords,
                    'secType': secType,
                    'column': column,
                    'sectorSub': sectorSub,
                    'oneOrTwo': oneOrTwo,
                    'column2': column2,
                    'filters': filters,
                    'colTab2': colTab2,

                }
                const key = generateUniqueIdentifier(stateObject)
                const existing_storage = JSON.parse(localStorage.getItem('stateObject')) || {}
                // console.log("Star is active ")
                const stateObjectKey = {}
                stateObjectKey[key] = stateObject
                existing_storage[key] = stateObject
                setStarActive(true)
                localStorage.setItem('stateObject', JSON.stringify(existing_storage));
                setNewFavoriteSearches(existing_storage)
                setCurrentFavouriteKey(currentFavouriteKey + 1)
            } else {
                console.log("0")
                const existing_storage = JSON.parse(localStorage.getItem('stateObject')) || {}
                // console.log("deleting the favourite ")
                // delete existing_storage[key]
                const remaining_storage = removeObjectKeyByIndex(existing_storage, currentFavouriteKey)
                // console.log(remaining_storage)
                setStarActive(false)
                localStorage.setItem('stateObject', JSON.stringify(remaining_storage));
                const newSearches = favSearches.filter((search, i) => i !== currentFavouriteKey);
                setFavSearches(newSearches);
                setCurrentFavouriteKey(currentFavouriteKey - 1)


            }

        }

        function getObjectByIndex(obj, index) {
            const keys = Object.keys(obj);
            const values = Object.values(obj);

            if (index >= 0 && index < keys.length) {
                const key = keys[index];
                const value = values[index];
                const result = {};
                result[key] = value;
                return result;
            }

            return null; // Return null if the index is out of bounds
        }

        function removeObjectKeyByIndex(obj, indexToRemove) {
            const keys = Object.keys(obj);
            if (indexToRemove >= 0 && indexToRemove < keys.length) {
                const keyToRemove = keys[indexToRemove];
                const updatedObject = {...obj};
                delete updatedObject[keyToRemove];
                return updatedObject;
            }
            return obj;
        }

        const handleCustomDropdown = (event) => {
            setCustomDropdown(!customDropdown);
        };

        const sec_id_search = (sec_id) => {
            console.log(sec_id)
            console.log(secIdDataList[sec_id])

            const value = secIdDataList[sec_id];
            if (value) {
                setSecTypeSub([secIdDataList[sec_id][2]])
                setSecType([secIdDataList[sec_id][3]])
                setSecId([secIdDataList[sec_id][1]])
                setKeywords([secIdDataList[sec_id][0]])
                // // console.log(relatedCorpsIds[index][1])
                fetchRightChartData(
                    [secIdDataList[sec_id][1]],
                    [secIdDataList[sec_id][2]],
                    selectedDataColumn,
                    checkedValuesMonths,
                    checkedValuesQuarter,
                    [secIdDataList[sec_id][0]],
                    [secIdDataList[sec_id][3]],
                    column,
                    sectorSub,
                    oneOrTwo,
                    "holders",
                    filters,
                    "holders"
                ).then(r => {
                })

                // insert value to searched terms
                const newSearchedTerms = [secIdDataList[sec_id]];
                setSearchedTerms(newSearchedTerms);
                // // console.log(lastSearches);
                // // console.log(value)
                if (!lastSearches.some(list => JSON.stringify(list) === JSON.stringify(value))) {
                    const newSearches = [...lastSearches, value];
                    setLastSearches(newSearches);
                }
            }
        }

        const handleRelatedCorpID = (index) => {
            const value = relatedCorpsIds[index];
            if (secId.includes(value[1])) {
                setVisibleSearchPopup(false);
                setVisibleSuggestions(false);
                search.value = "";
                search.placeholder = "";
                return
            }
            setSecTypeSub([relatedCorpsIds[index][2]])
            setSecType([relatedCorpsIds[index][3]])
            setSecId([relatedCorpsIds[index][1]])
            setKeywords([relatedCorpsIds[index][0]])
            // // console.log(relatedCorpsIds[index][1])
            fetchRightChartData(
                [relatedCorpsIds[index][1]],
                [relatedCorpsIds[index][2]],
                selectedDataColumn,
                checkedValuesMonths,
                checkedValuesQuarter,
                [relatedCorpsIds[index][0]],
                [relatedCorpsIds[index][3]],
                column,
                sectorSub,
                oneOrTwo,
                "holders",
                filters,
                "holders"
            ).then(r => {
            })

            // insert value to searched terms
            const newSearchedTerms = [relatedCorpsIds[index]];
            setSearchedTerms(newSearchedTerms);
            // // console.log(lastSearches);
            // // console.log(value)
            if (!lastSearches.some(list => JSON.stringify(list) === JSON.stringify(value))) {
                const newSearches = [...lastSearches, value];
                setLastSearches(newSearches);
            }

            // push the value to last searches
            // const newSearches = [...lastSearches, relatedCorpsIds[index]];
            // setLastSearches(newSearches);
        }

        const handleRelatedCorpIDMultiSearch = (index) => {
            // // console.log("in a add value")
            const value = relatedCorpsIds[index]
            if (secId.includes(value[1])) {
                setVisibleSearchPopup(false);
                setVisibleSuggestions(false);
                search.value = "";
                search.placeholder = "";
                return
            }

            setSecTypeSub([...secTypeSub, relatedCorpsIds[index][2]])
            setSecType([...secType, relatedCorpsIds[index][3]])
            setSecId([...secId, relatedCorpsIds[index][1]])
            setKeywords([...keywords, relatedCorpsIds[index][0]])
            setCheckedValuesQuarter(checkedValuesDefault)
            setCheckedValuesMonths(checkedValuesDefault)
            fetchRightChartData(
                [...secId, relatedCorpsIds[index][1]],
                [...secTypeSub, relatedCorpsIds[index][2]],
                selectedDataColumn,
                checkedValuesDefault,
                checkedValuesDefault,
                [...keywords, relatedCorpsIds[index][0]],
                [...secType, relatedCorpsIds[index][3]],
                column,
                sectorSub,
                oneOrTwo,
                "holders",
                filters,
                "holders"
            ).then(r => {
            })
            const newSearchedTerms = [value, ...searchedTerms];
            setSearchedTerms(newSearchedTerms);
            search.value = "";
            search.placeholder = "";
            setVisibleSearchPopup(false);
            setVisibleSuggestions(false);
        }

        const addValue = (index,) => {
            // // console.log("in a add value")

            const value = searchSuggestions[index];
            if (secId.includes(value[1])) {
                setVisibleSearchPopup(false);
                setVisibleSuggestions(false);
                search.value = "";
                search.placeholder = "";
                return
            }

            setSecId([...secId, searchSuggestions[index][1]])
            setKeywords([...keywords, searchSuggestions[index][0]])
            setSecTypeSub([...secTypeSub, searchSuggestions[index][2]])
            setSecType([...secType, searchSuggestions[index][3]])
            setSector([...sector, searchSuggestions[index][4]])
            setSectorSub([...sectorSub, searchSuggestions[index][5]])
            setCheckedValuesQuarter(checkedValuesDefault)
            setCheckedValuesMonths(checkedValuesDefault)

            fetchRightChartData([...secId, searchSuggestions[index][1]], [...secTypeSub, searchSuggestions[index][2]], selectedDataColumn, checkedValuesDefault, checkedValuesDefault, [...keywords, searchSuggestions[index][0]], [...secType, searchSuggestions[index][3]], column, [...sectorSub, searchSuggestions[index][5]]).then(r => {
            })

            // insert value to searched terms
            const newSearchedTerms = [value, ...searchedTerms];
            setSearchedTerms(newSearchedTerms);
            search.value = "";
            search.placeholder = "";

            // push the value to last searches
            if (!lastSearches.some(list => JSON.stringify(list) === JSON.stringify(value))) {
                const newSearches = [...lastSearches, value];
                setLastSearches(newSearches);
            }
            setVisibleSearchPopup(false);
            setVisibleSuggestions(false);
            // setVisibleSuggestions(false);

            // console.log([...secType, searchSuggestions[index][3]])
        };


        const addValueFromFavoriteSearch = (index) => {
            const value = favSearches[index].split(" | ")
            // if (secId.includes(value[1])) {
            //     setVisibleSearchPopup(false);
            //     setVisibleSuggestions(false);
            //     setLastField(false)
            //     search.value = "";
            //     search.placeholder = "";
            //     return
            // }
            console.log(value)

            const existing_storage = JSON.parse(localStorage.getItem('stateObject')) || {}
            let obj = getObjectByIndex(existing_storage, index)
            obj = obj[Object.keys(obj)[0]]
            console.log(obj)

            fetchRightChartData(
                obj.secId,
                obj.secTypeSub,
                obj.selectedDataColumn,
                obj.checkedValuesMonths,
                obj.checkedValuesQuarter,
                obj.keywords,
                obj.secType,
                obj.column,
                obj.sectorSub,
                obj.oneOrTwo,
                obj.column2,
                obj.filters,
                obj.colTab2
            ).then(r => {
                setCurrentFavouriteKey(index)
                setStarActive(true)
                var result = obj.keywords.map(function (word) {
                    return [word];
                });
                setSearchedTerms(result)
                search.value = "";
                search.placeholder = "";
                setVisibleSearchPopup(false);
                setVisibleSuggestions(false);
                setLastField(false)
            })

        }

        const addValueFromSimilarSearch = (row) => {
            console.log(row)
            const value = row
            if (secId.includes(value[0])) {
                setVisibleSearchPopup(false);
                setVisibleSuggestions(false);
                setLastField(false)
                search.value = "";
                search.placeholder = "";
                return
            }
            setSecTypeSub([...secTypeSub, value[5]])
            setSecType([...secType, value[4]])
            setSecId([...secId, value[0]])
            setKeywords([...keywords, value[1]])
            setCheckedValuesQuarter(checkedValuesDefault)
            setCheckedValuesMonths(checkedValuesDefault)
            fetchRightChartData(
                [...secId, value[0]],
                [...secTypeSub, value[5]],
                selectedDataColumn,
                checkedValuesDefault,
                checkedValuesDefault,
                [...keywords, value[1]],
                [...secType, value[4]]
            ).then(r => {
            })
            const newSearchedTerms = [[value[1], value[0], value[5], value[4]], ...searchedTerms];
            setSearchedTerms(newSearchedTerms);
            search.value = "";
            search.placeholder = "";
            setVisibleSearchPopup(false);
            setVisibleSuggestions(false);
            setLastField(false)
        }


        const addValueFromRecentSearch = (index,) => {
            // // console.log("in a add value")
            const value = lastSearches[index]
            if (secId.includes(value[1])) {
                setVisibleSearchPopup(false);
                setVisibleSuggestions(false);
                setLastField(false)
                search.value = "";
                search.placeholder = "";
                return
            }
            setSecTypeSub([...secTypeSub, lastSearches[index][2]])
            setSecType([...secType, lastSearches[index][3]])
            setSecId([...secId, lastSearches[index][1]])
            setKeywords([...keywords, lastSearches[index][0]])
            setCheckedValuesQuarter(checkedValuesDefault)
            setCheckedValuesMonths(checkedValuesDefault)
            fetchRightChartData(
                [...secId, lastSearches[index][1]],
                [...secTypeSub, lastSearches[index][2]],
                selectedDataColumn,
                checkedValuesDefault,
                checkedValuesDefault,
                [...keywords, lastSearches[index][0]],
                [...secType, lastSearches[index][3]]
            ).then(r => {
            })
            const newSearchedTerms = [value, ...searchedTerms];
            setSearchedTerms(newSearchedTerms);
            search.value = "";
            search.placeholder = "";
            setVisibleSearchPopup(false);
            setVisibleSuggestions(false);
            setLastField(false)
        }


        const searchClicked = (e) => {
            if (secId !== [""] ||secId.length < 1) {
                setVisibleSearchPopup(!visibleSearchPopup);
                setLastField(false);
            } else if (secId === [""] || secId.length >= 1) {
                if (e.target.value.length > 0) {
                    setVisibleSearchPopup(false);
                    setLastField(false);
                    setVisibleSuggestions(true);
                }

                setVisibleSearchPopup(!visibleSearchPopup);
                setLastField(!visibleSearchPopup);
                // if search clicked again hide suggestions
                // if (visibleSuggestions) {
                //     setVisibleSuggestions(false);
                //     setLastField(true);
                // }
            }
        };

        const removeTerm = (index) => {
            const newTerms = searchedTerms.filter((term, i) => i !== index);
            setSearchedTerms(newTerms);
            const secid_ = secId.reverse().filter((term, i) => i !== index)
            const sectypesub_ = secTypeSub.reverse().filter((term, i) => i !== index)
            const sectype_ = secType.reverse().filter((term, i) => i !== index)
            const keywords_ = keywords.reverse().filter((term, i) => i !== index)
            setSecTypeSub(sectypesub_)
            setSecType(sectype_)
            setSecId(secid_)
            setKeywords(keywords_)
            setActiveClass("hidden")
            if (secid_.length > 0) {
                if (secid_.length === 1) {
                    setFilters(filters_start)
                }
                fetchRightChartData(secid_, sectypesub_, selectedDataColumn, checkedValuesDefault, checkedValuesDefault, keywords_).then(r => {
                })
            } else {
                clear_all()
            }


            // if searched terms is empty, display make input field empty
            if (newTerms.length <= 0) {
                search.value = "";
                // search.placeholder = "search by sec_id/sec_name, issuer, sector, indices";
                search.placeholder = "חפש ני”ע, מדדים, ענפים, מנפיקים";
                setFilters(filters_start)
                setIsCustomFilter(true)
            } else {
                search.placeholder = "הוסף ני”ע להשוואה";
                setIsCustomFilter(false)
            }
        };

        const clear_all = () => {
            fetchDataInit()
            setLoading(false)
            setSearchedTerms([])
            setSeries([])
            setLabelsData([])
            setLeftChartMsg(false)
            setbarMonthlyCategories([])
            setbarMonthlyData([])
            setbarQuarterlyData([])
            setbarQuarterlyCategories([])
            setGrandSum(0)
            setSecId([])
            setSecTypeSub([])
            setSecType([])
            setSector([])
            setSectorSub([])
            setToShowDuration(false)
            setToShowCap(false)
            setFilters(filters_start)
            setCustomFilters(custom_filters_start)
            setColumn2('holders')
            setColTab2('holders')
            setRelatedSecurities([])
            setDisplay(false)

        }


          if (appLoading) {
            return <div className="mt-[45vh]">
                <DrorLoader />
            </div>;
          }

        return (<div className="h-[90vh]">
            <div className="w-[55%]  ml-auto p-3 mr-1 pb-0">
                <div className="ml-auto w-full flex justify-between items-center rounded">
                    <div className="bg-white px-1 flex just items-center w-full rounded relative">
                        <button
                            onClick={toggleSearch}
                            className="bg-[#323232] p-1 rounded text-white w-[150px] text-sm font-bold whitespace-nowrap"
                        >
                            {/*advance search*/}
                            חיפוש מתקדם
                        </button>

                        {
                            searchedTerms.length !== 0 && (
                                <button className="pl-3">
                                    <IoCloseOutline
                                        className="mr-2"
                                        onClick={() => clear_all()}
                                    />
                                </button>
                            )
                        }
                        <input
                            type="text"
                            onClick={searchClicked}
                            onKeyUp={searchKeyUp}
                            // defaultValue={"search by sec_id/sec_name, issuer, sector, indices"}
                            placeholder="חפש ני”ע, מדדים, ענפים, מנפיקים"
                            id="search"
                            autoComplete="off"
                            className="p-2 w-full ml-3 text-right rounded-none placeholder:text-black text-sm focus:outline-none"
                        />
                        <div className="search-tags flex pr-0 text-sm">
                            {searchedTerms && searchedTerms.map(
                                (term, index) => (
                                    <span
                                        key={index}
                                        className="bg-[#E5E5E5] px-3 py-1 rounded flex text-xs text-black mr-2 justify-center whitespace-nowrap flex-nowrap items-center min-w-[120px]"
                                    >
                                      <button onClick={() => removeTerm(index)}>
                                        <IoCloseOutline
                                            className="mr-2"

                                        />
                                      </button>
                                        {" "}
                                        {term[0]}
                                     </span>)
                            )}
                        </div>

                        {
                            // true && (
                            visibleSearchPopup && (
                                <div className="search-popup bg-white p-3">
                                    <div className="flex justify-end items-center overflow-x-hidden">
                                        {lastSearches.map((search, index) => (
                                            <div
                                                className="bg-[#E5E5E5] px-3 py-1 rounded whitespace-nowrap  text-sm text-black mr-2 flex justify-center items-center cursor-pointer"
                                                key={index}

                                            >
                                                <IoCloseOutline
                                                    className="mr-2"
                                                    onClick={() => deleteItem(index)}
                                                />{" "}
                                                <span onClick={() => addValueFromRecentSearch(index)}>{search[0]}</span>
                                            </div>))}
                                        <span className="fw-semibold text-sm whitespace-nowrap">: חיפושים אחרונים </span>
                                    </div>
                                    <div className="flex justify-end  items-center mt-2 overflow-x-hidden">
                                        {favSearches.map((search, index) => (
                                            <div
                                                className="bg-[#E5E5E5] px-3 py-1 rounded whitespace-nowrap text-sm text-black mr-2 flex justify-center items-center cursor-pointer"
                                                key={index}
                                            >
                                                {/*<IoCloseOutline*/}
                                                {/*    className="mr-2"*/}
                                                {/*    onClick={() => deleteFavItem(index)}*/}
                                                {/*/>{" "}*/}
                                                <span onClick={() => addValueFromFavoriteSearch(index)}>{search}</span>
                                            </div>))}
                                        <span className="fw-semibold text-sm whitespace-nowrap">: מועדפים</span>
                                    </div>
                                </div>
                            )
                        }
                        {
                            visibleSuggestions && searchSuggestions &&(
                                <div className="search-popup bg-white p-3">
                                    <div className="text-right">
                                        {
                                            searchSuggestions && searchSuggestions.length > 0 ? (
                                                    searchSuggestions && searchSuggestions.map((suggestion, index) => (
                                                        <button
                                                            key={index}
                                                            onClick={() => addValue(index)}
                                                            className="block mb-2 ml-auto font-semibold w-full text-right sugg-btn w-[50%]"
                                                        >
                                                            <div dir="rtl" className="flex">
                                                                <p className="flex-1">{suggestion[0]}</p>
                                                                <p className="flex-1">{suggestion[1]}</p>
                                                            </div>
                                                        </button>
                                                    ))
                                                )
                                                :
                                                (
                                                    <div className="text-center">אין מידע עבור החיפוש שהוקלד</div>
                                                )
                                        }
                                    </div>
                                </div>
                            )
                        }
                        {
                            lastField && relatedSecurities && relatedSecurities[0].length > 0 && (
                                // true && (
                                <div dir='rtl' style={{top: 132, maxHeight: 500, overflow: "auto"}}
                                     className="search-popup bg-white p-3 ">
                                    <div className="text-center">ניירות ערך דומים</div>
                                    {/*<div className="text-center">Similar Securities</div>*/}
                                    <div className="px-1 py-5">
                                        <table  className="table table-compact table-zebra  w-full table-hover">
                                            <thead className="text-center pt-5">
                                            <tr>
                                                <th className="text-sm font-semibold text-right px-5 p-1">
                                                    {/*sec_name*/}
                                                    שם נייר ערך
                                                </th>
                                                <th
                                                    style={{display: relatedSecurities[0][0][5] === null ? "none" : "true"}}
                                                    className="text-sm font-semibold px-5 p-1">
                                                    {/*sec_type_sub*/}
                                                    סוג ני”ע
                                                </th>
                                                <th
                                                    style={{display: relatedSecurities[0][0][7] === null ? "none" : "true"}}
                                                    className="text-sm font-semibold px-5 p-1">
                                                    {/*sector_sub*/}
                                                    ענף משנה
                                                </th>
                                                <th
                                                    style={{display: relatedSecurities[0][0][8] === null ? "none" : "true"}}
                                                    className="text-sm font-semibold px-5 p-1">
                                                    {/*atzmada_bonds*/}
                                                    הצמדה
                                                </th>

                                                <th
                                                    style={{display: relatedSecurities[0][0][11] === null ? "none" : "true"}}
                                                    className="text-sm font-semibold px-5 p-1 ">
                                                    {/*foreign_rate*/}
                                                    דירוג
                                                </th>
                                                <th
                                                    style={{display: relatedSecurities[0][0][13] === null ? "none" : "true"}}
                                                    className="text-sm text-right font-semibold px-5 p-1">
                                                    שווי שוק
                                                </th>
                                                <th
                                                    style={{display: relatedSecurities[0][0][15] === null ? "none" : "true"}}
                                                    className="text-sm font-semibold px-5 p-1">
                                                    מח”מ
                                                </th>

                                            </tr>
                                            </thead>

                                            <tbody className="text-center pt-5">
                                            {
                                                relatedSecurities[0].map(row => (
                                                    <tr key={row[0]}>
                                                        <td
                                                            onClick={() => addValueFromSimilarSearch(row)}
                                                            className="text-sm p-1 px-5 text-right cursor-pointer hover:font-bold">
                                                            {row[1]}
                                                        </td>
                                                        <td
                                                            style={{display: relatedSecurities[0][0][5] == null ? "none" : "true"}}
                                                            className="text-sm p-1 px-5">
                                                            {related_securities_legend['related_securities_sec_type_sub_legend'][row[5]] ? related_securities_legend['related_securities_sec_type_sub_legend'][row[5]] : row[5]}
                                                        </td>
                                                        <td
                                                            style={{display: relatedSecurities[0][0][7] == null ? "none" : "true"}}
                                                            className="text-sm p-1 px-5">
                                                            {related_securities_legend['related_securities_sector_sub_legend'][row[7]] ? related_securities_legend['related_securities_sector_sub_legend'][row[7]] : row[7]}
                                                        </td>
                                                        <td
                                                            style={{display: relatedSecurities[0][0][8] == null ? "none" : "true"}}
                                                            className="text-sm p-1 px-5">
                                                            {related_securities_legend['related_securities_atzmada_legend'][row[8]] ? related_securities_legend['related_securities_atzmada_legend'][row[8]] : row[8]}
                                                        </td>
                                                        <td
                                                            style={{display: relatedSecurities[0][0][11] == null ? "none" : "true"}}
                                                            className="text-sm p-1 px-5">
                                                            {related_securities_legend['related_securities_unify_rating_legend'][row[11]] ? related_securities_legend['related_securities_unify_rating_legend'][row[11]] : row[11]}
                                                        </td>
                                                        <td
                                                            style={{display: relatedSecurities[0][0][13] == null ? "none" : "true"}}
                                                            className="text-sm text-right p-1 px-5">{row[13] ? parseInt(row[13]).toLocaleString('en-US', {maximumFractionDigits: 0}) : ""}
                                                        </td>
                                                        <td
                                                            style={{display: relatedSecurities[0][0][15] == null ? "none" : "true"}}
                                                            className="text-sm p-1 px-5">{row[15]}
                                                        </td>

                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                    <div
                        className="btns flex justify-center items-center w-auto px-2 ml-2 h-[36px] overflow-hidden rounded bg-white">
                        <button onClick={() => getFromLocalStorage(-1)}
                                className="btn bg-white hover:bg-transparent border-0 p-0 text-black mx-2 text-xl">
                            <FiArrowLeft/>
                        </button>
                        <button onClick={() => getFromLocalStorage(1)}
                                className="btn bg-white hover:bg-transparent border-0 p-0 text-black mx-2 text-xl">
                            <FiArrowRight/>
                        </button>
                    </div>
                </div>
                {
                    secId.length <= 1 ?
                        <div className="relative px-2 z-20">
                            <div
                                className={`bg-white p-3 w-1/3 absolute top-0 z-10 ${activeClass}`}
                            >
                                <div className="flex justify-between items-start">
                                    <p
                                        onClick={cancel}
                                        className="close bg-transparent hover:bg-transparent border-0 text-black"
                                    >
                                        <FaTimes/>
                                    </p>

                                    <div className="text-right">
                                        <p className="font-semibold">{title_modal}</p>
                                        <p className="text-sm">{description}</p>
                                        <p className="text-sm">{description_}</p>
                                    </div>
                                </div>
                                {isCustomFilter ?
                                    <>

                                        <div className="mt-4">
                                            {customFilters && customFilters['sec_type_sub'] !== null &&
                                                <>
                                                    {/*<p className="text-sm mb-3">sec_type_sub</p>*/}
                                                    <select dir="rtl" onChange={(e) => filter_change(e, "sec_type_sub")}
                                                            className="w-full border py-2 rounded mb-3 "

                                                    >
                                                        <option key="sec_type_sub" value="sec_type_sub"> סוג ני”ע
                                                        </option>
                                                        {/*<option key="sel">sec_type_sub</option>*/}

                                                        {/*{*/}
                                                        {/*    customFilters['sec_type_sub'].map(*/}
                                                        {/*        value => (*/}
                                                        {/*            <option value={value.toString()}*/}
                                                        {/*                    key={value.toString()}>{customFilters['sec_type_sub_legend'][value] ? customFilters['sec_type_sub_legend'][value] : value}*/}
                                                        {/*            </option>*/}
                                                        {/*        )*/}
                                                        {/*    )   */}
                                                        {/*}*/}
                                                        {
                                                            customFilters['sec_type_sub'].map(value => ({
                                                                value: value,
                                                                display: customFilters['sec_type_sub_legend'][value] ? customFilters['sec_type_sub_legend'][value] : value
                                                            }))
                                                                .sort((a, b) => a.display.localeCompare(b.display))
                                                                .map(item => (
                                                                    <option value={item.value.toString()}
                                                                            key={item.value.toString()}>
                                                                        {item.display}
                                                                    </option>
                                                                ))
                                                        }

                                                    </select>
                                                    {/*<input type='text' defaultValue={filters['sec_type_sub'][0]} disabled={false}*/}
                                                    {/*       className="w-full border px-2 py-2 rounded mb-3 text-right"/>*/}
                                                </>
                                            }
                                            {customFilters && customFilters['sector_sub'].length > 0 && customFilters['sector_sub'][0] !== null &&
                                                <>
                                                    {/*<p className="text-sm mb-3">sector sub</p>*/}
                                                    <select dir="rtl" onChange={(e) => filter_change(e, "sector_sub")}
                                                            className="w-full border py-2 rounded mb-3">
                                                        <option key="sector_sub" value="sector_sub">ענף משנה</option>
                                                        {/*<option key="sel">sector_sub</option>*/}
                                                        {/*{customFilters['sector_sub'].map(value => (*/}
                                                        {/*    <option value={value.toString()}*/}
                                                        {/*            key={value.toString()}>{customFilters['sector_sub_legend'][value] ? customFilters['sector_sub_legend'][value] : value}</option>*/}
                                                        {/*))}*/}

                                                        {
                                                            customFilters['sector_sub'].map(value => ({
                                                                value: value,
                                                                display: customFilters['sector_sub_legend'][value] ? customFilters['sector_sub_legend'][value] : value
                                                            }))
                                                                .sort((a, b) => a.display.localeCompare(b.display))
                                                                .map(item => (
                                                                    <option value={item.value.toString()}
                                                                            key={item.value.toString()}>
                                                                        {item.display}
                                                                    </option>
                                                                ))
                                                        }
                                                    </select>
                                                    {/*<input type='text' defaultValue={filters['sector_sub'][0]} disabled={false}*/}
                                                    {/*       className="w-full border px-2 py-2 rounded mb-3 text-right"/>*/}

                                                </>
                                            }

                                            {customFilters && customFilters['atzmada'] != null && customFilters['atzmada'].length > 0 && customFilters['atzmada'][0] !== null &&
                                                <>
                                                    {/*<p className="text-sm mb-3">Atzmada</p>*/}
                                                    <select dir="rtl" onChange={(e) => filter_change(e, "atzmada")}
                                                            className="w-full border py-2 rounded mb-3">
                                                        <option key="atzmada" value="atzmada">הצמדה</option>
                                                        {/*<option key="sel">atzmada</option>*/}
                                                        {customFilters['atzmada'].map(value => (
                                                            <option value={value.toString()}
                                                                    key={value.toString()}>{customFilters['atzmada_legend'][value] ? customFilters['atzmada_legend'][value] : value}
                                                            </option>

                                                        ))}
                                                    </select>
                                                    {/*<input type='text' defaultValue={filters['sector_sub'][0]} disabled={false}*/}
                                                    {/*       className="w-full border px-2 py-2 rounded mb-3 text-right"/>*/}

                                                </>
                                            }

                                            {customFilters && customFilters['foreign_rate'] != null && customFilters['foreign_rate'].length > 0 && customFilters['foreign_rate'][0] !== null &&
                                                <>
                                                    {/*<p className="text-sm mb-3">Atzmada</p>*/}
                                                    <select dir="rtl" onChange={(e) => filter_change(e, "foreign_rate")}
                                                            className="w-full border py-2 rounded mb-3">
                                                        {/*<option key="sel">foreign_rate</option>*/}
                                                        <option key="foreign_rate" value="foreign_rate">דירוג</option>
                                                        {customFilters['foreign_rate'].map(value => (
                                                            <option value={value.toString()}
                                                                    key={value.toString()}>{customFilters['foreign_rate_legend'][value] ? customFilters['foreign_rate_legend'][value] : value}</option>

                                                        ))}
                                                    </select>
                                                    {/*<input type='text' defaultValue={filters['sector_sub'][0]} disabled={false}*/}
                                                    {/*       className="w-full border px-2 py-2 rounded mb-3 text-right"/>*/}

                                                </>
                                            }
                                            {/*{customFilters && customFilters['atzmada'][0] !== null &&*/}
                                            {/*    <>*/}
                                            {/*        <p className="text-sm mb-3">atzmada</p>*/}
                                            {/*        <select disabled className="w-full border py-2 rounded mb-3">*/}
                                            {/*            {customFilters['atzmada'].map(value => (*/}
                                            {/*                <option>{value}</option>*/}
                                            {/*            ))}*/}
                                            {/*        </select>*/}
                                            {/*        /!*<input type='text' defaultValue={filters['atzmada'][0]} disabled={false}*!/*/}
                                            {/*        /!*       className="w-full border px-2 py-2 rounded mb-3 text-right"/>*!/*/}
                                            {/*    </>*/}
                                            {/*}*/}
                                            {/*{customFilters && customFilters['rating'][0] !== null &&*/}
                                            {/*    <>*/}
                                            {/*        <p className="text-sm mb-3">foreign rate</p>*/}
                                            {/*        <select className="w-full border py-2 rounded mb-3">*/}
                                            {/*            {customFilters['rating'].map(value => (*/}
                                            {/*                <option>{value}</option>*/}
                                            {/*            ))}*/}
                                            {/*        </select>*/}
                                            {/*        /!*<input type='text' defaultValue={filters['rating'][0]} disabled={false}*!/*/}
                                            {/*        /!*       className="w-full border px-2 py-2 rounded mb-3 text-right"/>*!/*/}
                                            {/*    </>*/}
                                            {/*}*/}
                                            {/*<div className="mt-2 mb-10">*/}

                                            {/*    {*/}
                                            {/*        filters && toShowDuration &&*/}
                                            {/*        filters['s_duration_range'][0] !== 0 &&*/}
                                            {/*        filters['s_duration_range'][1] !== 0 &&*/}
                                            {/*        filters['s_duration_range'][2] !== 0 &&*/}
                                            {/*        filters['s_duration_range'][3] !== 0 &&*/}
                                            {/*        <>*/}
                                            {/*            <p className="text-sm mb-7 text-right font-bold"><span style={onHoverDur ? {*/}
                                            {/*                backgroundColor: "#333",*/}
                                            {/*                color: "#fff"*/}
                                            {/*            } : {}}> {formatNumber(filters['actual_dur'])} </span> מח”מ </p>*/}
                                            {/*            <MultiRangeSliderSmall*/}
                                            {/*                lowerLimit={filters['s_duration_range'][0]}*/}
                                            {/*                currentLower={filters['s_duration_range'][1]}*/}
                                            {/*                currentUpper={filters['s_duration_range'][2]}*/}
                                            {/*                upperLimit={filters['s_duration_range'][3]}*/}
                                            {/*                onChange={({min, max}) => {*/}
                                            {/*                    setrangeduration(min, max)*/}
                                            {/*                }}*/}
                                            {/*                actual={filters['actual_dur']}*/}
                                            {/*                setOnHover={setOnHoverDur}*/}

                                            {/*            />*/}
                                            {/*        </>*/}
                                            {/*    }*/}
                                            {/*</div>*/}
                                            <div className="mt-2 mb-10">
                                                {
                                                    customFilters['s_duration_range'] &&
                                                    customFilters['s_duration_range'].some(value => value !== 0) &&
                                                    // customFilters['s_duration_range'][0] !== 0 &&
                                                    // customFilters['s_duration_range'][1] !== 0 &&
                                                    // customFilters['s_duration_range'][2] !== 0 &&
                                                    // customFilters['s_duration_range'][3] !== 0 &&
                                                    <>
                                                        <p className="text-sm mb-7 text-right font-bold"><span
                                                            style={onHoverDur ? {
                                                                backgroundColor: "#333",
                                                                color: "#fff"
                                                            } : {}}> {formatNumber(customFilters['s_duration_range'][0] + customFilters['s_duration_range'][3]) / 2} </span> מח”מ
                                                        </p>
                                                        <MultiRangeSliderSmall
                                                            lowerLimit={customFilters['s_duration_range'][0]}
                                                            currentLower={customFilters['s_duration_range'][1]}
                                                            currentUpper={customFilters['s_duration_range'][2]}
                                                            upperLimit={customFilters['s_duration_range'][3]}
                                                            onChange={({min, max}) => {
                                                                setrangeduration(min, max)
                                                            }}
                                                            actual={(customFilters['actual_dur'][0] + customFilters['actual_dur'][3]) / 2}
                                                            setOnHover={setOnHoverDur}

                                                        />
                                                    </>
                                                }
                                            </div>
                                            <div className="mt-16 mb-14 ">
                                                {
                                                    customFilters['market_cap_range'] &&
                                                    customFilters['market_cap_range'][0] !== 0 &&
                                                    customFilters['market_cap_range'][1] !== 0 &&
                                                    customFilters['market_cap_range'][2] !== 0 &&
                                                    customFilters['market_cap_range'][3] !== 0 &&
                                                    <>
                                                        <p className="text-sm mb-7 text-right font-bold"><span
                                                            style={onHoverCap ? {
                                                                backgroundColor: "#333",
                                                                color: "#fff"
                                                            } : {}}> {formatNumber((customFilters['market_cap_range'][0] + customFilters['market_cap_range'][3]) / 2)}
                                                        </span> שווישוק </p>
                                                        <MultiRangeSlider
                                                            lowerLimit={customFilters['market_cap_range'][0]}
                                                            currentLower={customFilters['market_cap_range'][1]}
                                                            currentUpper={customFilters['market_cap_range'][2]}
                                                            upperLimit={customFilters['market_cap_range'][3]}
                                                            onChange={({min, max}) => {
                                                                setrangecap_custom(min, max)
                                                            }}
                                                            actual={(customFilters['market_cap_range'][0] + customFilters['market_cap_range'][3]) / 2}
                                                            setOnHover={setOnHoverCap}
                                                        />
                                                    </>
                                                }
                                            </div>
                                            <div className="flex justify-start items-center mt-20">

                                                <button
                                                    className="bg-gray-100 text-black px-3 py-2 ml-3"
                                                    onClick={cancel}
                                                >
                                                    בטל
                                                </button>
                                                <button
                                                    onClick={() => custom_filters_onclick()}
                                                    className="bg-[#6691E7] text-white px-3 py-2">
                                                    חפש
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>

                                        <div className="mt-4">
                                            {filters && filters['sec_type_sub'][0] !== null &&
                                                <>
                                                    {/*<p className="text-sm mb-3">sec_type_sub</p>*/}
                                                    {/*<select className="w-full border py-2 rounded mb-3">*/}
                                                    {/*    {filters['sec_type_sub'].map(value => (*/}
                                                    {/*            <option>{value}</option>*/}
                                                    {/*        )*/}
                                                    {/*    )}*/}

                                                    {/*</select>*/}
                                                    <input type='text' defaultValue={
                                                        filters && filters['sec_type_sub_legend'] && filters['sec_type_sub_legend'][filters['sec_type_sub'][0]] ?
                                                            filters['sec_type_sub_legend'][filters['sec_type_sub'][0]] : filters['sec_type_sub'][0]
                                                        // filters['sec_type_sub'][0]
                                                    }
                                                           disabled={false}
                                                           className="w-full border px-2 py-2 rounded mb-3 text-right"/>
                                                </>
                                            }
                                            {filters && filters['sector_sub'][0] !== null &&
                                                <>
                                                    {/*<p className="text-sm mb-3">sector sub</p>*/}
                                                    {/*<select className="w-full border py-2 rounded mb-3">*/}
                                                    {/*    {filters['sector_sub'].map(value => (*/}
                                                    {/*        <option>{value}</option>*/}
                                                    {/*    ))}*/}
                                                    {/*</select>*/}
                                                    <input type='text' defaultValue={
                                                        filters && filters['sector_sub_legend'] && filters['sector_sub_legend'][filters['sector_sub'][0]] ?
                                                            filters['sector_sub_legend'][filters['sector_sub'][0]] : filters['sector_sub'][0]
                                                        // filters['sector_sub'][0]
                                                    }
                                                           disabled={false}
                                                           className="w-full border px-2 py-2 rounded mb-3 text-right"/>

                                                </>
                                            }
                                            {filters && filters['atzmada'][0] !== null &&
                                                <>
                                                    {/*<p className="text-sm mb-3">atzmada</p>*/}
                                                    {/*<select disabled className="w-full border py-2 rounded mb-3">*/}
                                                    {/*    {filters['atzmada'].map(value => (*/}
                                                    {/*        <option>{value}</option>*/}
                                                    {/*    ))}*/}
                                                    {/*</select>*/}
                                                    <input type='text' defaultValue={

                                                        filters && filters['atzmada_legend'] &&  filters['atzmada_legend'][filters['atzmada'][0]] ?
                                                            filters['atzmada_legend'][filters['atzmada'][0]] : filters['atzmada'][0]
                                                    }

                                                           disabled={false}
                                                           className="w-full border px-2 py-2 rounded mb-3 text-right"/>
                                                </>
                                            }
                                            {filters && filters['rating'][0] !== null &&
                                                <>
                                                    {/*<p className="text-sm mb-3">foreign rate</p>*/}
                                                    {/*<select className="w-full border py-2 rounded mb-3">*/}
                                                    {/*    {filters['rating'].map(value => (*/}
                                                    {/*        <option>{value}</option>*/}
                                                    {/*    ))}*/}
                                                    {/*</select>*/}
                                                    <input type='text' defaultValue={
                                                        filters && filters['foreign_rate_legend'] && filters['foreign_rate_legend'][filters['rating'][0]] ?
                                                            filters['foreign_rate_legend'][filters['rating'][0]] : filters['rating'][0]
                                                    } disabled={false}
                                                           className="w-full border px-2 py-2 rounded mb-3 text-right"/>
                                                </>
                                            }
                                            <div className="mt-2 mb-10">

                                                {
                                                    filters && toShowDuration &&
                                                    filters['s_duration_range'].some(value => value !== 0) &&
                                                    // filters['s_duration_range'][0] !== 0 &&
                                                    // filters['s_duration_range'][1] !== 0 &&
                                                    // filters['s_duration_range'][2] !== 0 &&
                                                    // filters['s_duration_range'][3] !== 0 &&
                                                    <>
                                                        <p className="text-sm mb-7 text-right font-bold"><span
                                                            style={onHoverDur ? {
                                                                backgroundColor: "#333",
                                                                color: "#fff"
                                                            } : {}}> {formatNumber(filters['actual_dur'])} </span> מח”מ </p>
                                                        <MultiRangeSliderSmall
                                                            lowerLimit={filters['s_duration_range'][0]}
                                                            currentLower={filters['s_duration_range'][1]}
                                                            currentUpper={filters['s_duration_range'][2]}
                                                            upperLimit={filters['s_duration_range'][3]}
                                                            onChange={({min, max}) => {
                                                                setrangeduration(min, max)
                                                            }}
                                                            actual={filters['actual_dur']}
                                                            setOnHover={setOnHoverDur}

                                                        />
                                                    </>
                                                }
                                            </div>
                                            <div className="mt-16 mb-14 ">
                                                {
                                                    filters && toShowCap &&
                                                    filters['market_cap_range'][0] !== 0 &&
                                                    filters['market_cap_range'][1] !== 0 &&
                                                    filters['market_cap_range'][2] !== 0 &&
                                                    filters['market_cap_range'][3] !== 0 &&
                                                    <>
                                                        <p className="text-sm mb-7 text-right font-bold"><span
                                                            style={onHoverCap ? {
                                                                backgroundColor: "#333",
                                                                color: "#fff"
                                                            } : {}}> {formatNumber(filters['actual_cap'])} </span> שווי שוק
                                                        </p>
                                                        <MultiRangeSlider
                                                            lowerLimit={filters['market_cap_range'][0]}
                                                            currentLower={filters['market_cap_range'][1]}
                                                            currentUpper={filters['market_cap_range'][2]}
                                                            upperLimit={filters['market_cap_range'][3]}
                                                            onChange={({min, max}) => {
                                                                setrangecap(min, max)
                                                            }}
                                                            actual={filters['actual_cap']}
                                                            setOnHover={setOnHoverCap}
                                                        />
                                                    </>
                                                }
                                            </div>
                                            <div className="flex justify-start items-center mt-20">

                                                <button
                                                    className="bg-gray-100 text-black px-3 py-2 ml-3"
                                                    onClick={cancel}
                                                >
                                                    בטל
                                                </button>
                                                <button
                                                    onClick={() => applyFilters()}
                                                    className="bg-[#6691E7] text-white px-3 py-2">
                                                    חפש
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        : ""
                }
                <div className="text-right text-sm mt-3 text-white flex justify-end align-items-center">

                    {/*{relatedCorpsIds.map((row, index) => (*/}
                    {/*    <p*/}
                    {/*    key={index}*/}
                    {/*    onContextMenu={(event) => {*/}
                    {/*        event.preventDefault();*/}
                    {/*        handleRelatedCorpIDMultiSearch(index);*/}
                    {/*    }}*/}
                    {/*    title="Right click for multiple search Left click for new search"*/}
                    {/*    onClick={() => handleRelatedCorpID(index)}*/}
                    {/*    style={{whiteSpace: 'nowrap'}}*/}
                    {/*    className="mx-3 text-right cursor-pointer related_corps">*/}
                    {/*    {row[0]}*/}
                    {/*</p>*/}
                    {/*))*/}
                    {/*}*/}

                    {relatedCorpsIds.map((row, index) => (
                        <p dir="rtl"
                           key={index}
                           onContextMenu={(event) => {
                               event.preventDefault();
                               handleRelatedCorpIDMultiSearch(index);
                           }}
                           onClick={() => handleRelatedCorpID(index)}
                           className="mx-3 text-right cursor-pointer related_corps"
                           style={{
                               whiteSpace: 'nowrap',
                               position: 'relative'
                           }} // Add position relative for tooltip positioning
                        >
                            {row[0]}
                            <span className="tooltip">
                            <span className="tooltip-arrow"></span>
                            <span className="tooltip-text">קליק ימני - הוסף להשוואה</span>
                                {/*<span className="tooltip-text">Right click for multiple search</span>*/}
                                <br/>
                                {/*<span className="tooltip-text">Left click for new search</span>*/}
                                <span className="tooltip-text">קליק שמאלי - חיפוש חדש</span>
                            </span>
                        </p>
                    ))}
                    <p className="text-right related_corps mx-3 whitespace-nowrap"> : חיפושים קשורים </p>
                    {/*<p className="text-right related_corps mx-3 whitespace-nowrap"> : Related Search</p>*/}
                </div>
            </div>
            <Charts series={seriesData}
                // allCategories = {allCategories}
                    labels_data={labelsData}
                    num={labelsDataNum}
                    handleRowClick={handleRowClick}
                    allLabelsNames={allLabelsNames}
                    allQuarterNames={allQuarterNames}
                    bar_monthly_data={barMonthlyData}
                    bar_monthly_categories={barMonthlyCategories}
                    bar_quarterly_categories={barQuarterlyCategories}
                    bar_quarterly_data={barQuarterlyData}
                    grandSum={grandSum}
                    selectedRowId={selectedRowId}
                    handleCustomDropdownTop={handleCustomDropdownTop}
                    dates={dates}
                    handleCheckMonth={handleCheckMonth}
                    handleCheckQuarter={handleCheckQuarter}
                    checkedValuesMonths={checkedValuesMonths}
                    checkedValuesQuarter={checkedValuesQuarter}
                    display={display}
                    legands={lagends}
                    num_quarters={num_quarters}
                    loading={loading}
                    loadingleft={loadingLeft}
                    handleCustomDropdown={handleCustomDropdown}
                    customDropdown={customDropdown}
                    num_multi_search={secId.length}
                    selected_data_column={selectedDataColumn}
                    rightChartCatagories={rightChartCatagories}
                    secType={secType}
                    handleSelectionChange_={handleSelectionChange}
                    column={column}
                    keywords={keywords}
                    column2={column2}
                    handleSelectionChange2_={handleSelectionChange2}
                    activate_star_function={activate_star_function}
                    starActive={starActive}
                    isCustomFilter={isCustomFilter}
                    upper_chart_msg={left_chart_msg}
                    searchedSecName={keywords[0]}
                    sec_id_search={sec_id_search}
            />
        </div>)
            ;
    }
;

export default Search;
